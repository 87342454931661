import config from '@/services/config';

const modifyLink = (type) => {
  const url = new URL(config.host);
  url.pathname = '/yandex-metrika';
  url.searchParams.set('from', 'aboutLanding');
  url.searchParams.set('type', type);

  return url.href;
};

export default modifyLink;
