import axios from 'axios';
import config from '@/services/config';

class RemoteService {
  remoteClient;

  constructor(baseURL, options = {}) {
    this.remoteClient = axios.create({
      baseURL,
      timeout: config.requestTimeout,
      ...options,
    });
  }

  getRemoteClient() {
    return this.remoteClient;
  }

  async request(options = {}) {
    const { data } = await this.remoteClient(options);
    return data;
  }
}

export default RemoteService;
