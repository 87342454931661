import './bootstrap';

import { createApp } from 'vue';
import router from '@/router';
import store from '@/store';
import App from '@/App.vue';

import registerVuePlugins from './bootstrap-vue';

const app = registerVuePlugins(
  createApp(App),
);

app
  .use(store)
  .use(router)
  .mount('#app');
