import {
  API_GET_ORDER_LIST,
  API_GET_REGIONS,
  API_GET_CITIES,
  API_ORDER_LOADING_EVENTS,
  API_GET_ORDER_ADDITIONAL_INFO,
  API_GET_ORDER,
} from '@/services/endpoints';
import RemoteService from '@/services/remote';
import config from '@/services/config';

export default class OrderService extends RemoteService {
  constructor() {
    super(config.SRMOrderHost);
  }

  getOrders(filters, params) {
    return this.request({
      method: 'POST',
      url: API_GET_ORDER_LIST,
      params,
      data: filters,
    });
  }

  getRegions(params) {
    return this.request({
      method: 'GET',
      url: API_GET_REGIONS,
      params,
    });
  }

  getCities(params) {
    return this.request({
      method: 'GET',
      url: API_GET_CITIES,
      params,
    });
  }

  getOrderLoadingEvents(params) {
    return this.request({
      method: 'GET',
      url: API_ORDER_LOADING_EVENTS,
      params,
    });
  }

  getOrderAdditionalInfo(orderId) {
    return this.request({
      method: 'GET',
      url: API_GET_ORDER_ADDITIONAL_INFO(orderId),
    });
  }

  getOrder(orderId) {
    return this.request({
      method: 'GET',
      url: API_GET_ORDER(orderId),
    });
  }
}
