<template>
  <ui-modal
    :close-on-click-overlay="closeOnClickOverlay"
    :overlay-color="overlayColor"
    @close="onClose"
  >
    <div
      :class="$style.main"
    >
      <div
        :class="$style.container"
        :style="{ width: `${width}px` }"
        @click.stop
      >
        <button
          v-if="showClose"
          type="button"
          aria-label="Close"
          :class="$style.closeContainer"
          @click="onClose"
        >
          <svg-icon name="close" />
        </button>

        <div
          v-if="$slots.header"
          :class="$style.header"
        >
          <slot name="header" />
        </div>

        <slot />

        <div
          v-if="$slots.footer"
          :class="$style.footer"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </ui-modal>
</template>

<script>
import UiModal from '@/kit/UiModal.vue';
import SvgIcon from '@/kit/SvgIcon.vue';

export default {
  name: 'UiModalWithContent',

  components: {
    SvgIcon,
    UiModal,
  },
  props: {
    width: {
      type: Number,
      required: true,
    },
    isCentered: {
      type: Boolean,
      default: true,
    },
    closeOnClickOverlay: {
      type: Boolean,
      default: true,
    },
    overlayColor: {
      type: String,
      default: 'light',
      validate: (value) => [
        'light',
        'dark',
      ].includes(value),
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['close'],

  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style module>
.main {
  display: flex;
  place-content: flex-start center;
  place-items: flex-start center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.main.isCentered {
  place-content: center;
  place-items: center;
  min-height: 100%;
}

.container {
  position: relative;
  max-width: 100%;
}

@media screen and (max-width: 767px) {
  .container {
    max-width: calc(100vw - 32px);
  }
}

.container {
  border-radius: var(--border-radius-large);
  box-shadow: var(--modal-container-shadow);
  background-color: var(--color-white-100);
  padding: 32px 32px 48px;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 40px 24px 24px;
  }
}

.header {
  margin-bottom: 16px;
  font-size: var(--font-size-small-header);
  line-height: var(--line-height-header);
}

.footer {
  margin-top: 24px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.closeContainer {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  background: none;
  border: 0;
  box-shadow: none;
  padding: 0;
  width: 24px;
  height: 24px;
  color: var(--color-secondary);
  outline: none;
}
.closeContainer svg {
  width: 24px;
  height: 24px;
}

.closeContainer::before {
  content: '';
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
}
</style>
