<template>
  <table-generator
    v-click-outside="cellClear"
    :class="$style.table"
    :headers="headers"
    :rows="orders"
    :total-pages="totalPages"
    :size="size"
    :page="page"
    hide-head
    row-clickable
    :is-loading="isLoading"
    :theme="theme"
    is-top-aligned
    is-right-oriented
    is-striped
    is-hover
    @update:page="$emit('update:page', $event)"
    @update:size="$emit('update:size', $event)"
    @update:on-cell-click="onCellClick"
    @update:on-row-click="onRowClick"
    @load-more="$emit('load-more', $event)"
  >
    <template #[`col.order`]="{ item: order }">
      <order-number-cell
        :order-number="order.number"
        :order-date="order.date"
        :is-loading="isLoading"
      />
    </template>

    <template #[`col.route`]="{ item: order }">
      <div>
        <order-route-cell
          :city="order.loadingCityName"
          :region="order.loadingRegionName"
          :date="order.loadingDate"
          :is-loading="isLoading"
        />
      </div>
    </template>

    <template #[`col.direction`]="{ item: order }">
      <div>
        <order-direction-cell
          :order-id="order.orderId"
          :events-count="order.intermediateLoadingEventsCount"
          :is-loading="isLoading"
        />
      </div>
    </template>

    <template #[`col.routeEnd`]="{ item: order }">
      <order-route-cell
        :city="order.unloadingCityName"
        :region="order.unloadingRegionName"
        :date="order.unloadingDate"
        :is-loading="isLoading"
      />
    </template>

    <template #[`col.trailer`]="{ item: order }">
      <order-trailer-type-cell
        :is-tent="order.isTent"
        :temperature-from="order.temperatureFrom"
        :temperature-to="order.temperatureTo"
        :is-loading="isLoading"
      />
    </template>

    <template #[`col.price`]="{ item: order }">
      <order-price-cell
        :price="order.priceWithTaxes"
        :is-loading="isLoading"
      />
    </template>

    <template #[`col.book`]="{ item: order }">
      <order-book-cell
        :order-id="order.orderId"
        :is-loading="isLoading"
      />
    </template>

    <template #cardsTop="{ item: order, index: i }">
      <div
        :class="[$style.cardLink, $style.flex]"
        @click="onRowClick(i)"
      >
        <order-number-cell
          :order-number="order.number"
          :order-date="order.date"
          :is-loading="isLoading"
          is-card
        />

        <order-trailer-type-cell
          :is-tent="order.isTent"
          :temperature-from="order.temperatureFrom"
          :temperature-to="order.temperatureTo"
          :is-loading="isLoading"
          is-card
        />

        <order-price-cell
          :price="order.priceWithTaxes"
          :is-loading="isLoading"
          is-card
        />
      </div>
    </template>
    <template #cardsBottom="{ item: order, index: i }">
      <div
        :class="[$style.route, $style.cardLink]"
        @click="onRowClick(i)"
      >
        <order-route-cell
          :city="order.loadingCityName"
          :region="order.loadingRegionName"
          :date="order.loadingDate"
          :is-loading="isLoading"
          is-card
        />

        <div :class="$style.direction">
          <order-direction-cell
            :order-id="order.orderId"
            :events-count="order.intermediateLoadingEventsCount"
            :is-loading="isLoading"
          />
        </div>

        <order-route-cell
          :city="order.unloadingCityName"
          :region="order.unloadingRegionName"
          :date="order.unloadingDate"
          :is-loading="isLoading"
          is-card
        />
      </div>
    </template>
  </table-generator>
</template>

<script setup>
import {
  reactive,
  computed,
} from 'vue';

import config from '@/services/config';
import TableGenerator from '@/kit/TableGenerator.vue';
import OrderNumberCell from '@/components/cells/OrderNumberCell.vue';
import OrderRouteCell from '@/components/cells/OrderRouteCell.vue';
import OrderTrailerTypeCell from '@/components/cells/OrderTrailerTypeCell.vue';
import OrderBookCell from '@/components/cells/OrderBookCell.vue';
import OrderDirectionCell from '@/components/cells/OrderDirectionCell.vue';
import OrderPriceCell from '@/components/cells/OrderPriceCell.vue';

defineProps({
  headers: {
    type: Array,
    default: null,
  },
  orders: {
    type: Array,
    default: null,
  },
  totalPages: {
    type: Number,
    default: 1,
  },
  size: {
    type: Number,
    default: null,
  },
  page: {
    type: Number,
    default: 1,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'update:page',
  'update:size',
  'load-more',
  'click:order',
]);

const theme = computed(() => config.theme);

const activeInfo = reactive({
  index: null,
  head: null,
});
const onCellClick = ({ cell, i }) => {
  activeInfo.index = i;
  activeInfo.head = cell.key;
};
const cellClear = () => {
  activeInfo.index = null;
  activeInfo.head = null;
};
const onRowClick = (index) => {
  emit('click:order', index);
};
</script>

<style module>
.table {
  margin-bottom: 20px;
}

.route {
  position: relative;
  padding-left: 48px;
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.direction {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.cardLink {
  text-decoration: none;
}

.flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
</style>
