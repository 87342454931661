<template>
  <div
    :class="{ [$style.dark]: isDarkTheme }"
    @click="$emit('click')"
  >
    <div class="content-area">
      <div
        :class="{
          [$style.content]: true,
          [$style.hasButton]: hasButton,
        }"
        :style="{
          backgroundImage: backgroundImage,
          backgroundPosition: backgroundPosition,
        }"
      >
        <h1
          :class="{
            [$style.title]: true,
            [$style.largeTitle]: isLargeTitle,
            [$style.smallTitle]: isSmallTitle,
          }"
        >
          {{ title }}
        </h1>

        <p
          v-if="text || textSecond"
          :class="{
            [$style.text]: true,
            [$style.list]: textList,
          }"
        >
          <span
            v-if="text"
            :class="{
              [$style.block]: true,
              [$style.blockDesktop]: !!textList,
            }"
          >
            {{ text }}
          </span>
          <span
            v-if="textSecond"
            :class="{
              [$style.block]: true,
              [$style.blockDesktop]: !!textList,
            }"
          >
            {{ textSecond }}
          </span>

          <template v-if="textList">
            <span
              v-for="(item, index) in textList"
              :key="index"
              :class="[$style.block, $style.blockMobile]"
            >
              {{ item }}
            </span>
          </template>
        </p>

        <slot name="content" />
        <slot name="button" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import config from '@/services/config';

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  text: {
    type: String,
    default: null,
  },
  textSecond: {
    type: String,
    default: null,
  },
  textList: {
    type: Array,
    default: null,
  },
  isLargeTitle: {
    type: Boolean,
    default: false,
  },
  isSmallTitle: {
    type: Boolean,
    default: false,
  },
  hasButton: {
    type: Boolean,
    default: false,
  },
  image: {
    type: String,
    default: null,
  },
  backgroundPosition: {
    type: String,
    default: null,
  },
  theme: {
    type: String,
    default: 'light',
    validate: (value) => ['dark', 'light'].includes(value),
  },
});

defineEmits(['click']);

const isDarkTheme = computed(() => props.theme === config.themes.DARK);
const backgroundImage = computed(() => {
  if (!props.image) {
    return null;
  }

  return `url(${props.image})`;
});
</script>

<style module>
.content {
  height: 274px;
  padding: 32px 0;
  color: var(--color-secondary-darker);
}

.dark .content {
  color: var(--color-primary-dark-theme);
  background-position: right center;
  background-size: auto;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
  .content {
    max-height: 205px;
    padding: 24px 0;
  }

  .hasButton.content {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
        'header header'
        'text button';
  }
}

.title {
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxxlarge-header);
  line-height: var(--line-height-xsmall);
  font-weight: var(--font-weight-semibold);
  margin-top: 0;
  margin-bottom: 0;
  max-width: 90%;
}

.dark .title {
  font-size: var(--font-size-banner-small-header);
}

.title.largeTitle {
  line-height: var(--line-height-xxlarge);
  max-width: 60%;
}
.title.smallTitle {
  font-size: var(--font-size-banner-small-header);
  line-height: var(--line-height-medium);
}

@media screen and (max-width: 767px) {
  .title.largeTitle,
  .title.smallTitle,
  .title,
  .dark .title.largeTitle,
  .dark .title.smallTitle,
  .dark .title {
    font-size: var(--font-size-medium);
    line-height: var(--line-height-xxlarge);
    max-width: 270px;
    grid-area: header;
  }

  .title.smallTitle {
    line-height: var(--line-height-medium);
  }
}

.text {
  margin-top: 8px;
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-medium-header);
  line-height: var(--line-height-xxxlarge);
  max-width: 700px;
}
@media screen and (max-width: 767px) {
  .text {
    font-size: var(--font-size-base);
    line-height: var(--line-height-header);
    max-width: 275px;
  }

  .hasButton .text {
    grid-area: text;
    max-width: 185px;
  }
}

.block {
  display: block;
}
.blockMobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .blockDesktop {
    display: none;
  }
  .blockMobile {
    display: block;
    position: relative;
    background-image: url('~@/assets/img/checkmark.svg');
    background-repeat: no-repeat;
    background-position: left top;
    padding-left: 24px;
  }
  .blockMobile:not(:last-child) {
    margin-bottom: 2px;
  }
}
</style>
