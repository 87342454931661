<template>
  <footer :class="classes">
    <div class="content-area">
      <div :class="$style.content">
        <ul :class="$style.list">
          <li
            v-click-outside="close"
            :class="$style.dropdown"
          >
            <span
              :class="$style.link"
              @click="isDropdownVisible = true"
            >
              {{ t('footer.documents') }}
            </span>
            <ul
              v-if="isDropdownVisible"
              :class="$style.dropdownList"
            >
              <li :class="$style.dropdownItem">
                <div
                  :class="[$style.dropdownLink, $style.link]"
                  @click="onDownloadFile('privacy-policy')"
                >
                  {{ t('footer.privacyPolicy') }}
                </div>
              </li>
              <li :class="$style.dropdownItem">
                <a
                  :class="[$style.dropdownLink, $style.link]"
                  href="https://docs.google.com/document/d/1-QJXO33-SDnJzjIkChpyI4ve7YRu-dtf9yM0KE1RgMQ"
                  target="_blank"
                >
                  {{ t('footer.userGuide') }}
                </a>
              </li>
              <li :class="$style.dropdownItem">
                <div
                  :class="[$style.dropdownLink, $style.link]"
                  @click="onDownloadFile('func-characteristics')"
                >
                  {{ t('footer.funcCharacteristics') }}
                </div>
              </li>
              <li :class="$style.dropdownItem">
                <div
                  :class="[$style.dropdownLink, $style.link]"
                  @click="onDownloadFile('tech-support')"
                >
                  {{ t('footer.techSupport') }}
                </div>
              </li>
            </ul>
          </li>
          <li>
            <a
              :class="$style.link"
              href="https://globaltruck.ru/contacts"
              target="_blank"
            >
              {{ t('footer.contacts') }}
            </a>
          </li>
          <li>
            <a
              :class="$style.link"
              href="https://globaltruck.ru"
              target="_blank"
            >
              {{ t('footer.about') }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup>
import {
  ref,
  computed,
  useCssModule,
  unref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import config from '@/services/config';
import CarriersService from '@/services/CarriersService';
import { downloadFile } from '@/utils/files';
import { getHTTPError } from '@/utils/errors';

const style = useCssModule();
const { t } = useI18n();
const toast = useToast();

const isDropdownVisible = ref(false);

const isDarkTheme = computed(() => config.theme === config.themes.DARK);

const classes = computed(() => ({
  [style.container]: true,
  [style.dark]: unref(isDarkTheme),
}));

const close = () => {
  isDropdownVisible.value = false;
};

const carriersService = new CarriersService();
const onDownloadFile = async (fileType) => {
  let file = null;

  try {
    switch (fileType) {
      case 'func-characteristics':
        file = await carriersService.downloadFuncCharacteristics();
        downloadFile(file, 'Функц. характеристики.docx');
        break;
      case 'privacy-policy':
        file = await carriersService.downloadPrivacyPolicy();
        downloadFile(file, 'Политика конфиденциальности.pdf');
        break;
      case 'tech-support':
        file = await carriersService.downloadTechSupport();
        downloadFile(file, 'Тех. поддержка.docx');
        break;
      default:
        break;
    }
  } catch (e) {
    toast.error(getHTTPError(e));
  }

  close();
};
</script>

<style module>
.container {
  padding: 24px 0;
  background: var(--color-white-100);
}
@media screen and (max-width: 767px) {
  .container {
    padding: 16px 0;
  }
}
.dark.container {
  background: var(--color-primary-dark-theme);
}
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0;
  padding: 0;
  list-style: none;
}

@media screen and (max-width: 767px) {
  .list {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 0;
  }

  .list li:not(:last-child) {
    margin-top: 16px;
  }
}

.link {
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-base);
  line-height: var(--line-height-medium);
  color: var(--color-rich-black);
  text-decoration: none;
  cursor: pointer;
}
.dark .link {
  color: var(--color-white-100);
}
.link:hover {
  color: var(--color-primary);
}

.dropdown {
  position: relative;
}

.dropdownList {
  position: absolute;
  min-width: 100%;
  bottom: calc(100% + 8px);
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: var(--border-radius-base);
  box-shadow: var(--multiselect-shadow);
  background: var(--color-white-100);
  overflow: hidden;
}

.dropdownLink {
  display: block;
  padding: 12px;
  min-height: 40px;
  background: var(--color-white-100);
  color: var(--color-secondary-darker);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-normal);
}

.dark .dropdownLink {
  color: var(--color-rich-black);
}
.dark .dropdownLink:hover {
  color: var(--color-primary);
}
</style>
