export const formatPrice = (value) => {
  if (!Number.isFinite(value)) {
    return '';
  }

  const d = new Intl.NumberFormat('ru');

  return d.format(value);
};

export default { formatPrice };
