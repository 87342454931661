<template>
  <div :class="classes">
    <div
      v-if="!isLoading"
      @click="isLoadingEventsVisible = true"
    >
      <div
        v-if="date"
        :class="[$style.text, $style.bold]"
      >
        {{ formatDate(date) }},
      </div>
      <div
        v-if="city"
        :class="$style.text"
      >
        <div>
          {{ city }}<span v-if="hasCityAndRegion">,</span>
        </div>
        <div>{{ region }}</div>
      </div>
    </div>

    <template v-else>
      <ui-skeleton
        v-if="isLoading"
        :class="$style.skeleton"
        width="80px"
        height="18px"
        :rounded="true"
      />
      <ui-skeleton
        v-if="isLoading"
        :class="$style.skeleton"
        width="152px"
        height="18px"
        :rounded="true"
      />
    </template>

    <cell-info
      v-if="isLoadingEventsVisible && showInfo"
      @close="isLoadingEventsVisible = false"
    >
      <div>
        {{ t('table.hint') }}
      </div>
    </cell-info>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  useCssModule,
  unref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/filters/date';
import UiSkeleton from '@/kit/UiSkeleton.vue';
import CellInfo from '@/components/cells/CellInfo.vue';

const style = useCssModule();
const { t } = useI18n();

const props = defineProps({
  city: {
    type: String,
    default: null,
  },
  region: {
    type: String,
    default: null,
  },
  date: {
    type: String,
    default: null,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  showInfo: {
    type: Boolean,
    default: false,
  },
  isCard: {
    type: Boolean,
    default: false,
  },
});

const hasCityAndRegion = computed(() => props.city && props.region);

const isLoadingEventsVisible = ref(true);

const classes = computed(() => ({
  [style.container]: true,
  [style.card]: unref(props.isCard),
}));
</script>

<style module>
.container {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}
.text {
  position: relative;
  display: block;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height-medium);
  color: var(--color-secondary-darker);
}
/* .text:not(:last-child),
.skeleton:not(:last-child) {
  margin-bottom: 4px;
} */

@media screen and (max-width: 767px) {
  .text:not(:last-child),
  .skeleton:not(:last-child) {
    margin-bottom: 0;
  }
}

.card .bold {
  font-weight: var(--font-weight-medium);
}

@media screen and (max-width: 767px) {
  .text {
    gap: 4px;
    flex-wrap: wrap;
    flex-direction: row;
  }
}
</style>
