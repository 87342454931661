<template>
  <div :class="classes">
    <div class="content-area">
      <div :class="[$style.filters, $style.filtersMobile]">
        <div
          :class="{
            [$style.filtersActions]: true,
            [$style.active]: isMobileFiltersOpened,
          }"
        >
          <ui-input-text
            v-if="!isMobileFiltersOpened && filtersResult"
            v-model="filtersResult"
            :class="$style.input"
            :readonly="true"
            @click.stop="isMobileFiltersOpened = true"
          />
          <div
            :class="$style.filtersButton"
            @click.stop="isMobileFiltersOpened = !isMobileFiltersOpened"
          >
            <svg-icon
              :class="$style.icon"
              :name="isMobileFiltersOpened ? 'close' : 'filter'"
            />
          </div>
        </div>

        <div v-show="isMobileFiltersOpened">
          <div :class="$style.label">
            {{ t('filters.change') }}
          </div>

          <div :class="$style.filtersRoutes">
            <div>
              <order-route-filter
                :place="loadingPlace"
                :class="[$style.select, $style.selectFirst]"
                :placeholder="t('filters.loadingPlace')"
                :regions-list="regionsList"
                @update:model-value="$emit('update:loading-place', $event)"
              />
            </div>
            <ui-button
              :class="$style.iconButton"
              icon="swap"
              appearance="clean"
              @click="swap"
            />
            <div>
              <order-route-filter
                :place="unloadingPlace"
                :class="[$style.select, $style.selectSecond]"
                :placeholder="t('filters.unloadingPlace')"
                :regions-list="regionsList"
                @update:model-value="$emit('update:unloading-place', $event)"
              />
            </div>

            <div :class="$style.filtersRow">
              <period-filter
                :load-date-from="loadDateFrom"
                :load-date-to="loadDateTo"
                :placeholder="t('filters.loadingDate')"
                :class="{
                  [$style.period]: true,
                  [$style.section]: true,
                  [$style.opened]: isDatepickerOpened,
                }"
                @update:load-date-from="$emit('update:load-date-from', $event)"
                @update:load-date-to="$emit('update:load-date-to', $event)"
                @open="isDatepickerOpened = true"
                @close="isDatepickerOpened = false"
              />
              <div :class="[$style.section, $style.filtersTent]">
                <order-tent-filter
                  :is-tent="isTent"
                  :class="[$style.select, $style.selectLast]"
                  :placeholder="t('filters.isTent')"
                  @update:model-value="$emit('update:is-tent', $event)"
                />
              </div>
            </div>
            <ui-button
              :class="$style.button"
              @click="submit"
            >
              {{ t('filters.submit') }}
            </ui-button>
          </div>
        </div>
      </div>

      <div :class="$style.filters">
        <div :class="$style.group">
          <div>
            <order-route-filter
              :place="loadingPlace"
              :class="[$style.select, $style.selectFirst]"
              :placeholder="t('filters.loadingPlace')"
              :regions-list="regionsList"
              @update:model-value="$emit('update:loading-place', $event)"
            />
          </div>
          <ui-button
            :class="$style.iconButton"
            icon="swap"
            appearance="clean"
            @click="swap"
          />
          <div>
            <order-route-filter
              :place="unloadingPlace"
              :class="[$style.select, $style.selectSecond]"
              :placeholder="t('filters.unloadingPlace')"
              :regions-list="regionsList"
              @update:model-value="$emit('update:unloading-place', $event)"
            />
          </div>
          <period-filter
            :load-date-from="loadDateFrom"
            :load-date-to="loadDateTo"
            :placeholder="t('filters.loadingDate')"
            :class="{
              [$style.period]: true,
              [$style.opened]: isDatepickerOpened,
            }"
            @update:load-date-from="$emit('update:load-date-from', $event)"
            @update:load-date-to="$emit('update:load-date-to', $event)"
            @open="isDatepickerOpened = true"
            @close="isDatepickerOpened = false"
          />
          <div>
            <order-tent-filter
              :is-tent="isTent"
              :class="[$style.select, $style.selectLast]"
              :placeholder="t('filters.isTent')"
              @update:model-value="$emit('update:is-tent', $event)"
            />
          </div>
          <ui-button
            :class="$style.button"
            @click="$emit('submit')"
          >
            {{ t('filters.submit') }}
          </ui-button>
        </div>
      </div>

      <dates
        :class="$style.dates"
        :is-loading="isLoading"
        @update="changeDates"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  useCssModule,
  unref,
  reactive,
  computed,
  onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import config from '@/services/config';
import { formatDate } from '@/filters/date';
import useFiltersLists from '@/composable/orders/useFiltersLists';
import SvgIcon from '@/kit/SvgIcon.vue';
import UiButton from '@/kit/UiButton.vue';
import UiInputText from '@/kit/UiInputText.vue';
import OrderRouteFilter from '@/components/filters/OrderRouteFilter.vue';
import OrderTentFilter from '@/components/filters/OrderTentFilter.vue';
import PeriodFilter from '@/components/filters/PeriodFilter.vue';
import Dates from '@/components/dates/Dates.vue';

const style = useCssModule();

const props = defineProps({
  loadingPlace: {
    type: Object,
    default: null,
  },
  unloadingPlace: {
    type: Object,
    default: null,
  },
  isTent: {
    type: [Object, Boolean],
    default: null,
  },
  loadDateFrom: {
    type: [Object, Boolean],
    default: null,
  },
  loadDateTo: {
    type: [Object, Boolean],
    default: null,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'update:is-tent',
  'update:load-date-from',
  'update:load-date-to',
  'update:loading-place',
  'update:unloading-place',
  'submit',
]);

const { t } = useI18n();

const isDarkTheme = computed(() => config.theme === config.themes.DARK);

const classes = computed(() => ({
  [style.container]: true,
  [style.dark]: unref(isDarkTheme),
}));

const regionsList = ref([]);

const { getRegions } = useFiltersLists();

const setRegions = async () => {
  regionsList.value = await getRegions();
};

const isDatepickerOpened = ref(false);
const isMobileFiltersOpened = ref(false);

const tentTypesList = reactive([
  {
    name: 'Все',
    value: null,
  },
  {
    name: 'Тент',
    value: true,
  },
  {
    name: 'Реф',
    value: false,
  },
]);

const tentType = computed(() => {
  if (typeof props.isTent === 'boolean') {
    return tentTypesList?.find((item) => item.value === props.isTent)?.name;
  }
  return null;
});

const filtersResult = computed(() => {
  let result = '';

  if (props.loadingPlace?.name) {
    result += props.loadingPlace.name;
  }

  if (props.unloadingPlace?.name) {
    result += ` - ${props.unloadingPlace.name}, `;
  }

  if (props.loadDateFrom) {
    result += formatDate(props.loadDateFrom, 'DD MMMM');
  }

  if (props.loadDateTo) {
    result += ` - ${formatDate(props.loadDateTo, 'DD MMMM')}, `;
  }

  if (props.isTent) {
    result += tentType.value;
  }

  return result;
});

const swap = () => {
  const loadingPlace = props.loadingPlace ? { ...unref(props.loadingPlace) } : null;
  const unloadingPlace = props.unloadingPlace ? { ...unref(props.unloadingPlace) } : null;

  emit('update:loading-place', unloadingPlace);
  emit('update:unloading-place', loadingPlace);
};

const changeDates = (date) => {
  emit('update:load-date-from', date);
  emit('submit');
};

const submit = () => {
  isMobileFiltersOpened.value = false;
  emit('submit');
};

onMounted(() => {
  setRegions();
});
</script>

<style module>
.container {
  position: relative;
  margin-bottom: 24px;
}
@media screen and (max-width: 767px) {
  .container {
    background: var(--color-white-100);
  }
}
.filters {
  padding: 26px;
  background: var(--color-white-100);
  border: 1px solid var(--color-white-130);
  box-sizing: border-box;
  box-shadow: var(--form-shadow);
  border-radius: var(--border-radius-large);
  position: relative;
  z-index: var(--z-index-filters);
}
.dark .filters {
  box-shadow: none;
}
.filtersMobile {
  display: none;
}
@media screen and (max-width: 767px) {
  .filters {
    display: none;
  }

  .filtersMobile {
    display: block;
    padding: 16px 0;
    border: none;
    box-shadow: none;
    margin-bottom: 0;
  }

  .filtersMobile.filters {
    display: block;
  }
}
.filters .select {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid var(--color-gray-light);
  cursor: pointer;
}
.filters :global .select.multiselect--active {
  border-color: var(--color-primary);
}
.filters :global .select.multiselect--active {
  background: var(--color-white-100);
}
.filters .selectFirst {
  border-top-left-radius: var(--border-radius-base);
  border-bottom-left-radius: var(--border-radius-base);
  border-right: 0;
}
.filters .selectFirst:global.multiselect--active {
  border-right: 1px solid var(--color-primary);
  border-color: var(--color-primary);
}
.filters .selectSecond {
  border-left: 1px solid transparent;
}
.filters .selectSecond:global.multiselect--active {
  border-left: 1px solid var(--color-primary);
  border-color: var(--color-primary);
}
.filters .selectLast {
  border-right: 1px solid transparent;
}
.filters .selectLast:global.multiselect--active {
  border-color: var(--color-primary);
}
@media screen and (max-width: 767px) {
  .filters .selectFirst {
    border-right: 1px solid var(--color-gray-light);
  }
  .filters .selectFirst:global.multiselect--active {
    border-right: 1px solid var(--color-primary);
  }
  .filters .selectSecond {
    border-left: 1px solid var(--color-gray-light);
  }
  .filters .selectSecond:global.multiselect--active {
    border-left: 1px solid var(--color-primary);
  }
  .filters .selectLast {
    border-right: 1px solid var(--color-gray-light);
  }
  .filters .selectLast:global.multiselect--active {
    border-color: var(--color-primary);
  }
  .filters .select {
    border-radius: var(--border-radius-base);
  }

  .filters :global .multiselect__single,
  .filters :global .multiselect__input,
  .filters :global .multiselect__placeholder {
    font-size: var(--font-size-base);
    line-height: var(--line-height-medium);
    font-weight: var(--font-weight-medium);
  }

  .filters :global .multiselect {
    height: 48px;
  }
  .filters :global .multiselect__tags {
    padding: 16px;
  }

  .filtersRow {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .section {
    flex: 1;
  }

  .period {
    border-radius: var(--border-radius-base);
  }

  .filtersTent {
    width: 125px;
  }
}
.group {
  display: grid;
  grid-template-columns: 260px 56px 260px 218px 150px 1fr;
  grid-column-gap: 0;
  position: relative;
  z-index: 1;
}
.filtersRoutes {
  position: relative;
  display: flex;
  flex-direction: column;
}
.filters .iconButton {
  position: relative;
  border-top: 1px solid var(--color-gray-light);
  border-bottom: 1px solid var(--color-gray-light);
  height: 100%;
  border-radius: 0;
}
.filters .iconButton:hover {
  border-top: 1px solid var(--color-gray-light);
  border-bottom: 1px solid var(--color-gray-light);
  background: var(--color-secondary-lightest);
}
.iconButton::before,
.iconButton::after {
  content: '';
  position: absolute;
  top: calc(50% - 20px);
  height: 40px;
  width: 1px;
  background: var(--color-gray-light);
  z-index: 1;
}
.iconButton::before {
  left: -1px;
}
.iconButton::after {
  right: -1px;
}
@media screen and (max-width: 767px) {
  .filters .iconButton {
    margin-top: -8px;
    margin-bottom: -8px;
    margin-left: auto;
    border: 1px solid var(--color-gray-light);
    height: 32px;
    width: 32px;
    border-radius: 50%;
    z-index: var(--z-index-swap-button);
    background: var(--color-white-100);
    transform: rotate(90deg);
  }
  .filters .iconButton:hover {
    border: 1px solid var(--color-gray-light);
  }

  .filters .iconButton::before,
  .filters .iconButton::after {
    display: none;
  }
}
.button {
  height: 100%;
  border-radius: 0 var(--border-radius-base) var(--border-radius-base) 0;
}
@media screen and (max-width: 767px) {
  .button {
    border-radius: var(--border-radius-base);
    width: 100%;
    height: 40px;
    margin-bottom: 8px;
  }
}
.period {
  margin: 0 -1px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  border-top: 1px solid var(--color-gray-light);
  border-bottom: 1px solid var(--color-gray-light);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  cursor: pointer;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .period {
    margin: 16px 0;
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;
    border: 1px solid var(--color-gray-light);
  }

  .period :global .mx-input-wrapper {
    font-size: var(--font-size-base);
    line-height: var(--line-height-medium);
    font-weight: var(--font-weight-medium);
  }
}
.period:hover {
  background: var(--color-secondary-lightest);
  border-color: var(--color-gray-light);
}
.period.opened {
  border-color: var(--color-primary);
  background: var(--color-white-100);
}

.filtersButton {
  margin-left: auto;
  color: var(--color-primary);
  cursor: pointer;
}

.active .filtersButton {
  color: var(--color-secondary);
}

.filtersActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.input {
  flex: 1;
}

.icon {
  width: 24px;
  height: 24px;
}

.label {
  margin-top: 16px;
  margin-bottom: 20px;
}

.dates {
  display: none;
}

@media screen and (max-width: 767px) {
  .dates {
    display: block;
  }
}
</style>
