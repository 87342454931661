<template>
  <div
    :class="$style.container"
  >
    <ui-datepicker
      :model-value="loadingDate"
      inline
      range
      @update:model-value="update"
      @open="$emit('open', $event)"
      @close="$emit('close', $event)"
    >
      <template #input>
        <span>
          {{ label || placeholder }}
        </span>
      </template>
    </ui-datepicker>
  </div>
</template>

<script setup>
import {
  unref,
  computed,
} from 'vue';
import UiDatepicker from '@/kit/UiDatepicker.vue';
import { getDateForCurrentTimeZone } from '@/utils/handlers';
import { formatDate } from '@/filters/date';

const props = defineProps({
  loadDateFrom: {
    type: [Object, String],
    default: null,
  },
  loadDateTo: {
    type: [Object, String],
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
});

const emit = defineEmits([
  'update:loadDateFrom',
  'update:loadDateTo',
  'open',
  'close',
]);

const loadingDate = computed(() => {
  if (!props.loadDateFrom || !props.loadDateTo) {
    return null;
  }
  return [props.loadDateFrom, props.loadDateTo];
});

const label = computed(() => {
  let result = null;

  const start = formatDate(unref(props.loadDateFrom), 'D MMM, ddd');
  const end = formatDate(unref(props.loadDateTo), 'D MMM, ddd');

  if (!start && !end) {
    return null;
  }
  result = `${start} ${start ? '-' : ''} ${end}`;

  return result;
});

const update = (val) => {
  if (!val || !val[0] || !val[1]) {
    emit('update:loadDateFrom', null);
    emit('update:loadDateTo', null);
    return;
  }

  emit('update:loadDateFrom', getDateForCurrentTimeZone(val?.[0]));
  emit('update:loadDateTo', getDateForCurrentTimeZone(val?.[1]));
};
</script>

<style module>
.container {
  position: relative;
}
</style>
