<template>
  <ui-modal-with-content
    :width="622"
    overlay-color="dark"
    @close="$emit('close')"
  >
    <template #default>
      <div>
        <h2 :class="$style.title">
          {{ t('modal.title') }}
        </h2>

        <div :class="$style.container">
          <img
            :class="$style.image"
            :src="searchImg"
            :alt="t('modal.title')"
          >

          <div :class="$style.content">
            <p :class="$style.description">
              {{ t('modal.description') }}
            </p>
            <feedback-form
              :is-loading="isLoading"
              @submit="onSubmit"
            />
          </div>
        </div>
      </div>
    </template>
  </ui-modal-with-content>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import UiModalWithContent from '@/kit/UiModalWithContent.vue';
import FeedbackForm from '@/components/banners/feedback/FeedbackForm.vue';
import searchImg from '@/assets/img/search.png';
import CarriersService from '@/services/CarriersService';
import { getHTTPError } from '@/utils/errors';

const emit = defineEmits(['close', 'submit']);

const { t } = useI18n();
const toast = useToast();
const carriersService = new CarriersService();

const isLoading = ref(false);

const onSubmit = async ({
  phoneNumber,
  companyName,
  desiredDestinationAndDate,
}) => {
  isLoading.value = true;

  try {
    await carriersService.sendEmailForFeedback({
      phoneNumber,
      companyName,
      desiredDestinationAndDate,
    });
    emit('submit');
  } catch (e) {
    toast.error(getHTTPError(e));
  }

  isLoading.value = false;
};
</script>

<style module>
.container {
  display: flex;
  gap: 32px;
  color: var(--color-secondary-darker);
}
@media screen and (max-width: 767px) {
  .container {
    display: block;
  }
}
.content {
  flex: 1;
}
.title {
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxlarge-header);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-header);
  margin: 0 0 8px;
}
@media screen and (max-width: 767px) {
  .title {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-medium);
  }
}
.description {
  font-size: var(--font-size-medium);
  line-height: var(--line-height-xxxlarge);
  font-weight: var(--font-weight-semibold);
  margin-bottom: 32px;
}
@media screen and (max-width: 767px) {
  .description {
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-medium);
    margin-bottom: 24px;
  }
}
.image {
  margin-top: 62px;
  width: 175px;
  height: 176px;
}

@media screen and (max-width: 767px) {
  .image {
    display: none;
  }
}
</style>
