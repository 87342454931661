<template>
  <div :class="classes">
    <textarea
      :id="internalId"
      ref="input"
      :class="inputClasses"
      :value="modelValue"
      :disabled="disabled"
      v-bind="$attrs"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
    />

    <div
      v-if="isHaveAppendedContent"
      :class="$style.appendedContent"
    >
      <slot name="append">
        <svg-icon
          v-if="rightIcon"
          :class="$style.rightIcon"
          :name="rightIcon"
          @click.stop="$emit('click:rightIcon')"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/kit/SvgIcon.vue';
import inputMixin from '@/kit/mixins/input';

export default {
  name: 'UiTextarea',

  components: {
    SvgIcon,
  },

  mixins: [
    inputMixin,
  ],

  props: {
    resize: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'update:modelValue',
    'click:rightIcon',
  ],

  computed: {
    inputClasses() {
      return {
        [this.$style.input]: true,
        [this.$style.resize]: this.resize,
        [this.$style.isDisabled]: this.disabled,
      };
    },

    internalId() {
      // eslint-disable-next-line no-underscore-dangle
      return this.id;
    },
  },
};
</script>

<style module>
.container {
  display: flex;
  flex-flow: row;
  cursor: text;

  background-clip: padding-box;
  border-radius: var(--border-radius-base);
  box-shadow: inset 0 0 0 1px var(--color-secondary-light);
  background: var(--color-white-100);

  padding: 2px;

  transition: box-shadow var(--timing-input-animation) ease-in-out;
}

.container .appendedContent {
  margin-right: 4px;
}

.container.isDisabled {
  opacity: 0.33;
}
.container.isDisabled .rightIcon {
  color: var(--color-black-09);
  cursor: default;
  pointer-events: none;
}

.container.isInvalid {
  box-shadow: inset 0 0 0 1px var(--color-danger);
}

.container:not(.isDisabled):hover {
  box-shadow: inset 0 0 0 1px var(--color-secondary-light);
  background: var(--color-secondary-lightest);
}
.container:not(.isDisabled).isFocused {
  background: var(--color-white-100);
}

.container:not(.isDisabled).isFocused {
  box-shadow: inset 0 0 0 1px var(--color-primary);
}

.container:not(.isDisabled).isInvalid:hover {
  box-shadow: inset 0 0 0 1px var(--color-danger);
}

.container:not(.isDisabled).isInvalid .isFocused {
  box-shadow: inset 0 0 0 2px var(--color-danger);
}

.input {
  font: inherit;
  flex-grow: 1;
  outline: none;
  border: none;
  background: transparent;

  padding: 5px 10px 7px 10px;
  border-radius: var(--border-radius-base);
  min-height: var(--size-form-element-height);

  font-size: var(--font-size-medium);
  line-height: var(--line-height-xsmall);
  color: var(--color-black-100);
  resize: none;
}
.input.resize {
  resize: vertical;
}

.input::placeholder {
  color: var(--color-placeholder);
}

@media screen and (max-width: 767px) {
  .input {
    font-size: var(--font-size-base);
  }
}

.appendedContent {
  display: inline-flex;
}

.rightIcon {
  width: 24px;
  height: 24px;
  align-self: flex-start;
  cursor: pointer;
  color: var(--color-black-67);
  transition: color var(--timing-input-animation) ease-in-out;
}
.rightIcon:hover {
  color: var(--color-navy-100);
}
</style>
