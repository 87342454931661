/**
 * Форматирование телефонного номера
 * 78005553535 => +7 (800) 555-35-35
 *
 * @param value {number}
 * @return {string}
 */
export const formatPhone = (value) => {
  if (!value) {
    return '';
  }

  const cleaned = value.toString()
    .replace(/\D/g, '');

  const match = cleaned.match(/(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    return `+7 (${match[1]}) ${match[2]}-${match[3]}-${match[4]}`;
  }

  return `+${value}`;
};

/**
 * Форматирование телефонного номера c сокрытием части номера
 * 78005553535 => +7 (800) ***-**-35
 *
 * @param value {number}
 * @return {string}
 */

export const partialHidingPhone = (value) => {
  if (!value) {
    return '';
  }

  const cleaned = value.toString()
    .replace(/\D/g, '');

  const match = cleaned.match(/(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    return `+7 (${match[1]}) ***-**-${match[4]}`;
  }
  return `+${value}`;
};

export default {
  formatPhone,
  partialHidingPhone,
};
