const Tooltip = {
  mounted(el, binding) {
    init(el, binding);
  },
  updated(el, binding) {
    init(el, binding);
  },
};

function init(el, binding) {
  const tooltipElement = el?.querySelector('i.tooltip__element');

  if (tooltipElement) {
    return;
  }

  el.setAttribute('tooltip', true);

  const element = document.createElement('i');
  const color = binding?.value?.color || 'blue';
  const size = binding?.value?.size || 'normal';
  element?.classList?.add('tooltip__element');
  element?.classList?.add(color);
  element?.classList?.add(size);

  const position = binding?.value?.position || 'top';
  const tooltipTitle = binding?.value?.title || '';
  const tooltipText = binding?.value?.text || '';

  element.setAttribute('position', position);

  if (tooltipTitle) {
    const titleElement = document.createElement('div');
    titleElement?.classList?.add('tooltip__title');
    titleElement.innerHTML += tooltipTitle;
    element.appendChild(titleElement);
  }

  if (tooltipText) {
    const textElement = document.createElement('div');
    textElement?.classList?.add('tooltip__text');
    textElement.innerHTML += tooltipText;
    element.appendChild(textElement);
  }

  el.appendChild(element);
}

export default Tooltip;
