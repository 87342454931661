<template>
  <ui-modal-with-content
    :width="704"
    overlay-color="dark"
  >
    <template #default>
      <p :class="$style.modalTitle">
        {{ t('banners.bots.button') }}
      </p>
      <div :class="$style.modalMobileContent">
        <a
          :class="$style.link"
          :href="telegramLink"
          target="_blank"
        >
          <svg-icon
            name="telegram-circle"
            :class="$style.botIcon"
          />
          {{ t('banners.bots.modal.telegram') }}
        </a>
        <a
          :class="$style.link"
          :href="icqLink"
          target="_blank"
        >
          <svg-icon
            name="icq-circle"
            :class="$style.botIcon"
          />
          {{ t('banners.bots.modal.icq') }}
        </a>
      </div>
      <div :class="$style.modalDesktopContent">
        <p>
          {{ t('banners.bots.modal.title1') }}
        </p>
        <p>
          {{ t('banners.bots.modal.title2') }}
        </p>
        <div :class="$style.wrapper">
          <div>
            <div :class="$style.botTitle">
              <svg-icon
                name="telegram-circle"
                :class="$style.botIcon"
              />
              {{ t('banners.bots.modal.telegram') }}
            </div>
            <qr-code
              :class="$style.qrCode"
              :size="252"
              :text="telegramLinkQr"
            />
            <p :class="$style.alternativeText">
              {{ t('banners.bots.modal.alternative') }}
            </p>
            <a
              :class="$style.desktopLink"
              :href="telegramLink"
              target="_blank"
              @click="onClickTelegramLink"
            >
              {{ t('banners.bots.modal.linkTelegram') }}
            </a>
          </div>
          <div>
            <div :class="$style.botTitle">
              <svg-icon
                name="icq-circle"
                :class="$style.botIcon"
              />
              {{ t('banners.bots.modal.icq') }}
            </div>
            <qr-code
              :class="$style.qrCode"
              :size="252"
              :text="icqLinkQr"
            />
            <p :class="$style.alternativeText">
              {{ t('banners.bots.modal.alternative') }}
            </p>
            <a
              :class="$style.desktopLink"
              :href="icqLink"
              target="_blank"
              @click="onClickIcqLink"
            >
              {{ t('banners.bots.modal.linkICQ') }}
            </a>
          </div>
        </div>
      </div>
    </template>
  </ui-modal-with-content>
</template>

<script setup>
import QrCode from 'vue-qrcode-component';
import { useI18n } from 'vue-i18n';
import UiModalWithContent from '@/kit/UiModalWithContent.vue';
import SvgIcon from '@/kit/SvgIcon.vue';
import config from '@/services/config';
import modifyLink from '@/utils/qrCodeLinkModifier';
import useMetrika from '@/composable/useMetrika';

const { reachGoal } = useMetrika();
const onClickTelegramLink = () => {
  reachGoal('Transition_via_QR_switch_to_computer_via_showcase_added_click');
};
const onClickIcqLink = () => {
  reachGoal('Transition_via_switch_to_computer_ICQ_via_showcase_added_click');
};

const telegramLink = config.telegramNotificationBot;
const icqLink = config.icqNotificationBot;
const telegramLinkQr = modifyLink('telegram');
const icqLinkQr = modifyLink('icq');
const { t } = useI18n();
</script>

<style module>
.modalTitle {
  font-size: var(--font-size-banner-small-header);
  font-weight: var(--font-weight-medium);
  margin-bottom: 16px;
}

.modalMobileContent {
  display: none;
}

.modalDesktopContent {
  font-size: var(--font-size-medium);
}

.wrapper {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}

.qrCode {
  margin: 0 24px;
}

.botTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  font-size: var(--font-size-medium-header);
  font-weight: var(--font-weight-medium);
}
.botIcon {
  margin-right: 10px;
  width: 32px;
  height: 32px;
}

.alternativeText {
  margin: 12px 0 18px;
  text-align: center;
  color: var(--color-primary-dark-theme);
}

.desktopLink {
  display: block;
  text-align: center;
  color: var(--color-primary);
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .modalTitle {
    font-size: var(--font-size-medium-header);
    margin-bottom: 32px;
  }

  .modalDesktopContent {
    display: none;
  }

  .modalMobileContent {
    display: block;
    margin-bottom: 8px;
  }

  .link {
    display: flex;
    min-width: 300px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: var(--font-size-small-header);
    color: var(--color-secondary-extra-dark);
    font-weight: var(--font-weight-medium);
    margin-top: 12px;
    height: 60px;
    border-radius: var(--border-radius-xlarge);
    background: var(--color-secondary-lightest);
  }
}
</style>
