<template>
  <header :class="classes">
    <div class="content-area">
      <div :class="$style.panel">
        <div :class="$style.row">
          <a
            :href="isDarkTheme ? '/?theme=dark' : '/'"
            :class="$style.logoContainer"
          >
            <img
              :class="$style.logo"
              :src="logoImg"
            >
          </a>
          <a
            :href="isDarkTheme ? '/?theme=dark' : '/'"
            :class="$style.link"
          >
            {{ t('portal') }}
          </a>
        </div>

        <orders-counters :class="$style.counters" />

        <ui-button
          :url="href + metrikaParams"
          :class="[$style.right, $style.button]"
          :color="isDarkTheme ? 'primary' : 'alternate'"
          :size="'large'"
          append-icon="login"
          @click.prevent="onClickEnterPortal"
        >
          {{ t('header.login') }}
        </ui-button>
        <ui-button
          :url="href + regKeycloakURI"
          :class="[$style.button, $style.regButton]"
          color="primary"
          :size="'large'"
          @click.prevent="onClickRegisterPortal"
        >
          {{ t('header.reg') }}
        </ui-button>
        <ui-button
          :url="href"
          :class="[$style.right, $style.button, $style.buttonMobile]"
          icon="ava"
          @click.prevent="onClickEnterPortal"
        />
      </div>
    </div>
  </header>
</template>

<script setup>
import {
  computed,
  defineAsyncComponent,
  useCssModule,
  unref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import useMetrika from '@/composable/useMetrika';

import config from '@/services/config';
import UiButton from '@/kit/UiButton.vue';
import logoImg from '@/assets/img/logo.png';

const style = useCssModule();
const { t } = useI18n();
const store = useStore();
const { reachGoal } = useMetrika();

const OrdersCounters = defineAsyncComponent(
  () => import('@/components/counters/OrdersCounters.vue'),
);

const metrikaUser = computed(() => store.state.metrikaUser);
const metrikaClientId = computed(() => store.state.metrikaClientId);

const metrikaParams = computed(() => {
  let params = `?source=${window.location.hostname}`;

  if (metrikaClientId.value) {
    params += `&regId=${metrikaClientId.value}`;
  }

  if (metrikaUser?.value) {
    params += `&userEmail=${metrikaUser?.value?.email}`;
  }

  return params;
});

const href = computed(() => (`${config.host}`));

const onClickEnterPortal = () => {
  reachGoal('enterportal');
  window.location.assign(`${href.value}/?${metrikaParams.value}`);
};

const regKeycloakURI = computed(() => `auth/realms/globaltruck/protocol/openid-connect/registrations
?client_id=gt-arm
&redirect_uri=https%3A%2F%2Fportal.globaltruck.ru%2F%3F
source%3Dabout.globaltruck.ru%26regId%3D${metrikaClientId.value}
&response_mode=fragment&response_type=code&scope=openid`);

const onClickRegisterPortal = () => {
  reachGoal('enterportal');

  window.location.assign(`${href.value}${regKeycloakURI.value}`);
};

const isDarkTheme = computed(() => config.theme === config.themes.DARK);

const classes = computed(() => ({
  [style.container]: true,
  [style.dark]: unref(isDarkTheme),
}));
</script>

<style module>
.container {
  position: sticky;
  top: 0;
  background: var(--color-white-100);
  z-index: var(--z-index-header);
}
.dark.container {
  background: var(--color-primary-dark-theme);
}
.panel {
  padding: 13px 0 17px;
  position: relative;
  z-index: var(--z-index-panel);
  display: flex;
  align-items: center;
}

.dark .panel {
  padding: 20px 0;
}

.counters {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  left: -20px;
}

.dark .counters {
  left: 46px;
}

.row {
  display: flex;
  align-items: center;
}

.logoContainer {
  margin-top: -8px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  width: 40px;
  height: 38px;
}

.dark .logoContainer {
  width: 44px;
}

@media screen and (max-width: 767px) {
  .logoContainer,
  .dark .logoContainer {
    margin-right: 10px;
    width: 32px;
    height: 27px;
  }
}

.logo {
  display: block;
  width: 100%;
  height: auto;
}

.regButton {
  margin-left: 14px;
}

.right {
  margin-left: auto;
  text-align: right;
}
.link {
  color: var(--color-secondary-darkery);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  text-decoration: none;
}
.dark .link {
  color: var(--color-white-100);
  max-width: 100px;
  font-size: var(--font-size-base);
  line-height: var(--line-height-medium);
}

.dark .button {
  background: none;
  border: none;
  padding: 0;
}
.dark .button :global svg {
  width: 32px;
  height: 32px;
}

.buttonMobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .button {
    display: none;
  }

  .button.buttonMobile {
    display: block;
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .button.buttonMobile:hover {
    background-color: transparent;
    border: none;
  }

  .button :global svg {
    color: var(--color-primary);
    width: 24px;
    height: 24px;
  }
}

.dark .button:hover {
  color: var(--color-primary);
  background: none;
  border: none;
}
</style>
