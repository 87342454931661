<template>
  <div :class="classes">
    <div
      v-if="!isDarkTheme"
      :class="$style.imageBlock"
    >
      <img
        v-if="img"
        :class="$style.image"
        :src="img"
        :alt="title"
      >
    </div>
    <div :class="$style.content">
      <h3 :class="$style.title">
        {{ title }}
      </h3>
      <p
        :class="$style.text"
        v-html="text"
      />
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  useCssModule,
  unref,
} from 'vue';

const style = useCssModule();

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  text: {
    type: String,
    default: null,
  },
  img: {
    type: String,
    default: null,
  },
  fillImage: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'light',
    validate: (value) => ['dark', 'light'].includes(value),
  },
});

const isDarkTheme = computed(() => props.theme === 'dark');

const classes = computed(() => ({
  [style.container]: true,
  [style.dark]: unref(isDarkTheme),
  [style.fill]: unref(props.fillImage),
}));
</script>

<style module>
.container {
  background: var(--color-secondary-light);
  border-radius: var(--border-radius-xlarge);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.dark.container {
  border-left: 1px solid var(--color-white-130);
  border-right: 1px solid var(--color-white-130);
  border-bottom: 1px solid var(--color-white-130);
  border-top: 4px solid var(--color-primary-dark-theme);
}

@media screen and (max-width: 767px) {
  .container:not(.dark) {
    border-top: 4px solid var(--color-primary);
  }
}

.imageBlock {
  width: 100%;
  height: 130px;
  object-fit: cover;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .imageBlock {
    height: 104px;
  }
}

.image {
  transition: transform ease-in-out .6s;
}

@media screen and (max-width: 767px) {
  .imageBlock {
    display: none;
  }
}

.imageBlock:hover .image {
  transform: scale(1.1);
}

.content {
  flex: 1;
  background: var(--color-white-100);
  padding: 24px;
  border-left: 1px solid var(--color-white-130);
  border-right: 1px solid var(--color-white-130);
  border-bottom: 1px solid var(--color-white-130);
}

@media screen and (max-width: 767px) {
  .content {
    padding: 27px 16px 24px 16px;
  }
}

.dark .content {
  border: none;
}

.title {
  margin: 0;
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-small-header);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xlarge);
  color: var(--color-black-100);
}

@media screen and (max-width: 767px) {
  .title {
    font-size: var(--font-size-medium);
    line-height: var(--line-height-large);
  }
}

.text {
  max-width: 308px;
  margin-top: 8px;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height-xxxlarge);
  color: var(--color-black-100);
}

@media screen and (max-width: 767px) {
  .text {
    max-width: 255px;
    font-size: var(--font-size-xsmall);
    line-height: var(--line-height-xlarge);
  }
}

.text :global a {
  color: var(--color-primary);
  text-decoration: none;
}
.text :global a:hover {
  color: var(--color-primary-darker);
}
</style>
