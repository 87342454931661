<template>
  <div>
    <banner
      :class="classes"
      :title="t('banners.bots.title')"
      :is-small-title="true"
      :theme="theme"
    >
      <template #content>
        <ul :class="$style.contentList">
          <li>{{ t('banners.bots.text1') }}</li>
          <li>{{ t('banners.bots.text2') }}</li>
        </uL>
      </template>
      <template #button>
        <ui-button
          color="secondary"
          :class="$style.button"
          @click="isShowBotsModal = true"
        >
          {{ t('banners.bots.button') }}
        </ui-button>
      </template>
    </banner>
    <banner-bots-modal
      v-if="isShowBotsModal"
      @close="isShowBotsModal = false"
    />
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  useCssModule,
  unref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Banner from '@/components/banners/Banner.vue';
import UiButton from '@/kit/UiButton.vue';
import BannerBotsModal from '@/components/banners/BannerBotsModal.vue';

const style = useCssModule();

const props = defineProps({
  theme: {
    type: String,
    default: 'light',
    validate: (value) => ['dark', 'light'].includes(value),
  },
});

const { t } = useI18n();

const theme = computed(() => props.theme);
const isDarkTheme = computed(() => props.theme === 'dark');

const isShowBotsModal = ref(false);

const classes = computed(() => ({
  [style.container]: true,
  [style.dark]: unref(isDarkTheme),
}));
</script>

<style module>
.container {
  background-image: url('~@/assets/img/banner-bots.png'), var(--banner-bots-shadow);
  background-repeat: no-repeat;
  background-position: calc(50% + 24px) center, center;
  background-size: auto, cover;
}

.container :global .content-area {
  padding: 0 24px;
}

.contentList {
  margin: 10px 0 0;
  padding: 0;
  list-style: none;
}

.contentList li {
  background: none;
  margin-top: 4px;
  padding-left: 16px;
  font-size: var(--font-size-medium-header);
  color: var(--color-secondary-extra-dark);
  position: relative;
}

.contentList li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 7px;
  display: block;
  width: 8px;
  height: 8px;
  background: var(--color-secondary);
  border-radius: 50%;
}

.button {
  margin-top: 24px;
  min-height: 40px;
  min-width: 200px;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bold);
}

.botIcon {
  margin-right: 10px;
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 767px) {
  .container {
    position: relative;
    background-color: var(--color-banner-blue-light);
    background-image: url('~@/assets/img/banner-bots-mobile.png');
    background-repeat: no-repeat;
    background-position: 100% 38%;
  }

  .contentList {
    margin: 8px 0 0;
  }

  .contentList li {
    padding-left: 8px;
    font-size: var(--font-size-base);
  }

  .contentList li::before {
    width: 4px;
    height: 4px;
  }

  .button {
    padding: 0 8px;
    margin-top: 10px;
    min-height: unset;
    min-width: unset;
    font-size: var(--font-size-tiny);
  }

  .modalTitle {
    font-size: var(--font-size-medium-header);
    margin-bottom: 32px;
  }

  .modalDesktopContent {
    display: none;
  }

  .modalMobileContent {
    display: block;
    margin-bottom: 8px;
  }

  .link {
    display: flex;
    min-width: 300px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: var(--font-size-small-header);
    color: var(--color-secondary-extra-dark);
    font-weight: var(--font-weight-medium);
    margin-top: 12px;
    height: 60px;
    border-radius: var(--border-radius-xlarge);
    background: var(--color-secondary-lightest);
  }
}
</style>
