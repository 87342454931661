<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.dark]: isDarkTheme,
    }"
  >
    <div
      :class="{
        [$style.body]: true,
        [$style.isLoading]: isLoading,
        [$style.isResponsive]: isResponsive,
      }"
    >
      <table
        :class="{
          [$style.table]: true,
          [$style.isHover]: isHover,
          [$style.isStriped]: isStriped,
        }"
      >
        <thead
          v-if="!hideHead"
          :class="$style.thead"
        >
          <tr>
            <th
              v-if="withAdditionalRows"
              :class="$style.cell"
            />
            <th
              v-for="head in headers"
              :key="head.key"
              :class="{
                [$style.headerCell]: true,
                [$style.isSingleLine]: head.isSingleLine,
                [$style.isStickyLeft]: head.isStickyLeft,
                [$style.isStickyRight]: head.isStickyRight,
              }"
              scope="col"
              :data-qa="`table-title-headers-${head.key}`"
            >
              <div :class="$style.headerWrap">
                <slot
                  :name="`header.${head.key}`"
                  :head="head"
                >
                  {{ head.title }}
                </slot>
                <slot
                  v-if="$slots[`sort.${head.key}`]"
                  :name="`sort.${head.key}`"
                />
              </div>
              <div
                v-if="$slots[`filter.${head.key}`]"
                :class="$style.filter"
              >
                <slot :name="`filter.${head.key}`" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody
          v-for="(row, i) in rows"
          :key="i"
          :class="{
            [$style.tbody]: true,
          }"
        >
          <tr
            :class="{
              [$style.row]: true,
              [$style.clickableRow]: isClickable,
            }"
            :data-qa="`table-row-${i}`"
            :data-qa-id="getCustomQaKey(i)"
            @click="rowClick(row, i)"
            @mouseover="rowHover(row, i)"
          >
            <td
              v-if="withAdditionalRows"
              :class="$style.cell"
            >
              <svg-icon
                v-if="isHaveAdditional(row)"
                :class="{
                  [$style.additionalIcon]: true,
                  [$style.rotate]: isOpenRow(i),
                }"
                name="arrow_down"
              />
            </td>
            <td
              v-for="head in headers"
              :key="head.key"
              :class="{
                [$style.cell]: true,
                [$style.isStickyLeft]: head.isStickyLeft,
                [$style.isStickyRight]: head.isStickyRight,
              }"
              :data-qa="getQaKey(head.key, i)"
              @click="cellClick(head, row, i)"
            >
              <slot
                :name="`col.${head.key}`"
                :item="row"
                :qa-key="getQaKey(head.key, i)"
                :index="i"
                :head="head.key"
              >
                {{ row[head.key] }}
              </slot>
            </td>
          </tr>
          <tr
            v-for="(additionalRow, j) in row[additionalRows] || []"
            :key="j"
            :class="{
              [$style.row]: true,
              [$style.hideRows]: isAdditionalRowsHide(i),
            }"
            :data-qa="`table-row-${j}`"
          >
            <td :class="$style.additionalCell" />
            <td
              v-for="head in headers"
              :key="head.key"
              :class="{
                [$style.additionalCell]: true,
                [$style.isStickyLeft]: head.isStickyLeft,
                [$style.isStickyRight]: head.isStickyRight,
              }"
              :data-qa="getQaKey(head.key, j)"
            >
              <slot
                :name="`col.${head.key}`"
                :item="additionalRow"
                :qa-key="getQaKey(head.key, j)"
              >
                {{ additionalRow[head.key] }}
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ul :class="$style.cards">
      <li
        v-for="(row, index) in rows"
        :key="index"
        :class="$style.card"
      >
        <div :class="$style.cardTop">
          <slot
            name="cardsTop"
            :item="row"
            :index="index"
          />
        </div>
        <div :class="$style.cardBottom">
          <slot
            name="cardsBottom"
            :item="row"
            :index="index"
          />
        </div>
      </li>
    </ul>

    <div
      v-if="size || showLoadMore || (totalPages && totalPages > 1)"
      :class="$style.footer"
    >
      <template v-if="totalPages && totalPages > 1">
        <button
          v-if="showLoadMore"
          :class="{
            [$style.footerButton]: true,
            [$style.buttonLoading]: isLoading,
            [$style.disabled]: page >= totalPages,
          }"
          :disabled="page >= totalPages"
          @click="$emit('load-more', page + 1)"
        >
          <template v-if="!isLoading">
            {{ t('table.loadMore') }}
          </template>
          <template v-else>
            <img
              :class="$style.dots"
              :src="dotsImg"
            >
          </template>
        </button>

        <ui-pagination
          :class="$style.pagination"
          :page="page"
          :total-pages="totalPages"
          :is-loading="isLoading"
          @update:page="$emit('update:page', $event)"
        />
      </template>

      <div
        v-if="size"
        :class="$style.wrapperSize"
      >
        <ui-multi-select
          v-if="!isLoading"
          :model-value="sizeSelect"
          :class="$style.sizeSelect"
          :options="sizeItems"
          :show-clear-icon="false"
          :show-no-results="false"
          :show-copy-icon="false"
          size="small"
          placeholder="Показывать по"
          label="title"
          track-by="number"
          @update:model-value="$emit('update:size', $event.number)"
        />

        <div
          v-else
          :class="$style.skeletonWrapper"
        >
          <ui-skeleton
            width="145px"
            height="18px"
            :rounded="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import UiPagination from '@/kit/UiPagination.vue';
import UiMultiSelect from '@/kit/UiMultiSelect.vue';
import dotsImg from '@/assets/img/dots.svg';
import SvgIcon from '@/kit/SvgIcon.vue';
import UiSkeleton from '@/kit/UiSkeleton.vue';

export default {
  name: 'TableGenerator',

  components: {
    SvgIcon,
    UiPagination,
    UiMultiSelect,
    UiSkeleton,
  },

  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    totalPages: {
      type: Number,
      default: null,
    },
    page: {
      type: Number,
      default: null,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
    hideHead: {
      type: Boolean,
      default: false,
    },
    isResponsive: {
      type: Boolean,
      default: false,
    },
    isStriped: {
      type: Boolean,
      default: false,
    },
    isHover: {
      type: Boolean,
      default: false,
    },
    markLine: {
      type: Boolean,
      default: false,
    },
    rowClickable: {
      type: Boolean,
      default: false,
    },
    onCellClick: {
      type: Boolean,
      default: false,
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    additionalRows: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: null,
    },
    theme: {
      type: String,
      default: 'light',
      validate: (value) => ['dark', 'light'].includes(value),
    },
  },

  emits: [
    'update:on-row-click',
    'update:on-cell-click',
    'update:hovered-row',
    'update:size',
    'update:page',
    'load-more',
  ],

  data() {
    return {
      dotsImg,
      expandedRowsIndices: [],
      sizeItems: [
        {
          title: 'Показывать по 12',
          number: 12,
        },
        {
          title: 'Показывать по 24',
          number: 24,
        },
        {
          title: 'Показывать по 48',
          number: 48,
        },
      ],
    };
  },

  computed: {
    t() {
      return useI18n()?.t;
    },
    withAdditionalRows() {
      return this.additionalRows.length > 0;
    },
    rowIds() {
      return this.rows.map((item) => item.aggregateId);
    },
    sizeSelect() {
      return this.sizeItems.find((item) => item.number === this.size);
    },
    showLoadMore() {
      return this.page <= this.totalPages;
    },
    isDarkTheme() {
      return this.theme === 'dark';
    },
  },

  methods: {
    rowClick(row, i) {
      if (this.rowClickable) {
        this.$emit('update:on-row-click', i);
      }
      if (this.expandedRowsIndices.includes(i)) {
        this.expandedRowsIndices = this.expandedRowsIndices
          .filter((rowIndex) => rowIndex !== i);
      } else {
        this.expandedRowsIndices.push(i);
      }
    },

    cellClick(cell, row, i) {
      if (this.onCellClick) {
        this.$emit('update:on-cell-click', {
          cell,
          row,
          i,
        });
      }
    },

    rowHover(row) {
      if (this.isHover) {
        this.$emit('update:hovered-row', row);
      }
    },

    getQaKey(key, index) {
      return `${key}-${index}`;
    },

    getCustomQaKey(index) {
      return this.rowIds[index] ?? `table-row-${index}`;
    },

    isAdditionalRowsHide(parentRowIndex) {
      return !this.expandedRowsIndices.includes(parentRowIndex);
    },

    isHaveAdditional(row) {
      if (this.additionalRows.length === 0) return false;
      return row[this.additionalRows]?.length > 0;
    },

    isOpenRow(index) {
      return this.expandedRowsIndices.includes(index);
    },
  },
};
</script>

<style module>
.container {
  display: block;
  position: relative;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.body {
  margin-bottom: 16px;
  box-shadow: var(--form-shadow);
  border-radius: var(--border-radius-large);
  border: 1px solid var(--color-secondary-extra-light);
  min-height: 350px;
}
.dark .body {
  box-shadow: none;
}
.body.isResponsive {
  overflow-x: auto;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .body {
    display: none;
  }
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  --table-accent-bg: var(--color-white-100);
  --table-head-background: var(--color-light-gray);
}
.table > thead > tr {
  vertical-align: top;
}
.table.isStriped > tbody > tr:nth-of-type(odd) {
  --table-accent-bg: var(--color-background-light);
}
.table.isHover  > tbody > tr:hover {
  --table-accent-bg: var(--color-navy-07);
}

.table tr:hover td {
  transition: background ease .3s;
  background: var(--color-light-gray);
}

.thead {
}

.headerCell {
  padding: 16px 32px;
  border-top: 1px solid var(--color-white-130);
  border-bottom: 1px solid var(--color-white-130);
  text-align: left;
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  color: var(--color-slate-gray);
  z-index: 1;
}
.headerCell:first-child {
  border-top-left-radius: var(--border-radius-large);
}
.headerCell:last-child {
  border-top-right-radius: var(--border-radius-large);
}
.headerCell.isSingleLine {
  white-space: nowrap;
}
.headerCell.isStickyLeft {
  position: sticky;
  left: 0;
  border-right: 1px solid var(--color-light-gray-100);
}
.headerCell.isStickyRight {
  position: sticky;
  right: 0;
  border-left: 1px solid var(--color-light-gray-100);
}

.cell {
  padding: 16px 32px;
  vertical-align: middle;
  border-bottom: 4px solid var(--color-light-gray);
  font-size: var(--font-size-base);
  line-height: var(--line-height-medium);
  color: var(--color-slate-gray);
  background: var(--color-white-100);
}
.cell:first-child {
  border-left: 1px solid var(--color-white-130);
}
.cell:last-child {
  border-right: 1px solid var(--color-white-130);
}
.dark .cell:first-child {
  border-left: 0;
}
.dark .cell:last-child {
  border-right: 0;
}
.tbody:last-child .cell {
  border-bottom: 0;
}
.cell.isStickyLeft {
  position: sticky;
  left: 0;
  border-right: 1px solid var(--color-light-gray-100);
}
.cell.isStickyRight {
  position: sticky;
  right: 0;
  border-left: 1px solid var(--color-light-gray-100);
}

.tbody:first-child .cell:last-child {
  border-top-right-radius: var(--border-radius-large);
}
.tbody:first-child .cell:first-child {
  border-top-left-radius: var(--border-radius-large);
}
.tbody:last-child .cell:last-child {
  border-bottom-right-radius: var(--border-radius-large);
}
.tbody:last-child .cell:first-child {
  border-bottom-left-radius: var(--border-radius-large);
}

.filter {
  margin-top: 4px;
}

.coloredRow {
  --table-accent-bg: var(--color-primary) !important;
}

.clickableRow {
  cursor: pointer;
}

.hideRows {
  display: none;
}

.additionalIcon {
  transition: all 250ms ease-out;
  transform: rotate(0deg);
}

.rotate {
  transform: rotate(-180deg);
}

.additionalCell {
  padding: 6px 10px;
  vertical-align: middle;
  background: var(--color-white-100);
  border-bottom: 1px solid var(--color-background);
  font-size: var(--font-size-small);
}
.additionalCell.isStickyLeft {
  position: sticky;
  left: 0;
  border-right: 1px solid var(--color-light-gray-100);
}
.additionalCell.isStickyRight {
  position: sticky;
  right: 0;
  border-left: 1px solid var(--color-light-gray-100);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 32px;
}

@media screen and (max-width: 767px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.footerButton {
  flex: 1;
  flex-basis: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  padding: 10px 0;
  color: var(--color-secondary-darker);
  background: var(--color-white-100);
  border: 1px solid var(--color-secondary-light);
  border-radius: var(--border-radius-base);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height-medium);
  outline: none;
  cursor: pointer;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .footerButton {
    width: 100%;
  }
}

.footerButton:hover {
  border-color: var(--color-primary);
}

.footerButton.buttonLoading {
  padding: 0;
}

.footerButton.disabled {
  background: var(--color-white-100);
  border: 1px solid var(--color-secondary-extra-light);
  color: var(--color-secondary);
  cursor: not-allowed;
}

.sizeSelect {
  margin-left: auto;
  width: 180px;
}

.wrapperSize {
  display: flex;
  align-items: center;
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  .wrapperSize {
    margin-left: 0;
  }

  .pagination {
    margin-bottom: 16px;
  }
}

.textSize {
  font-size: var(--font-size-base);
  color: var(--color-black-67);
}

.headerWrap {
  display: flex;
}
.dots {
  width: 48px;
  height: 48px;
  color: var(--color-secondary);
}
.skeletonWrapper {
  margin-left: auto;
  padding: 7px 14px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-white-100);
  border: 1px solid var(--color-gray-light);
  border-radius: var(--border-radius-base);
  cursor: wait;
}

.cards {
  display: none;
  margin: 0 -16px 16px;
  padding: 0;
  list-style: none;
}

.card {
  padding: 16px;
  background: var(--color-white-100);
  border-radius: var(--border-radius-large);
  margin-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .card {
    padding: 16px 16px 14px;
  }
}

.card:last-child {
  margin-bottom: 0;
}

.cardTop {
  padding: 0 6px 18px 16px;
  border-bottom: 1px dashed var(--color-border-light);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.cardBottom {
  padding: 20px 6px 0 16px;
}

@media screen and (max-width: 767px) {
  .cards {
    display: block;
  }
}
</style>
