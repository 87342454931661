import '@/css/fonts.css';
import '@/css/global.css';
import '@/css/toast.css';
import '@/css/tooltip.css';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

require('dayjs/locale/ru');

dayjs.extend(utc);
dayjs.locale('ru');
