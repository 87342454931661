<template>
  <div
    v-click-outside="close"
    :class="$style.container"
  >
    <div
      :class="$style.action"
      @click="isOpen = !isOpen"
    >
      <div :class="$style.iconWrapper">
        <img :src="isOpen ? phoneCloseImg : phoneImg">
      </div>
    </div>

    <div
      v-if="isOpen"
      :class="$style.content"
    >
      <div :class="$style.top">
        <img
          :src="phoneLogoImg"
        >

        <div :class="$style.title">
          {{ t('contacts.title') }}
          <img :src="handImg">
        </div>
        <div :class="$style.text">
          {{ t('contacts.text') }}
        </div>
      </div>
      <div :class="$style.bottom">
        <div :class="$style.bottomTitle">
          {{ manager.name }}
        </div>

        <a
          :class="$style.number"
          :href="`tel:${manager.phone}`"
        >
          {{ formatPhone(manager.phone) }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { shuffle } from 'lodash';
import Cookie from 'js-cookie';
import phoneImg from '@/assets/img/phone.svg';
import phoneCloseImg from '@/assets/img/phone_close.svg';
import phoneLogoImg from '@/assets/img/phone_logo.svg';
import handImg from '@/assets/img/hand.svg';
import { formatPhone } from '@/filters/phone';
import useSpreadsheetManagers from '@/composable/useSpreadsheetManagers';

const store = useStore();
const { t } = useI18n();

const isOpen = ref(false);

const { getManagersList } = useSpreadsheetManagers();

getManagersList();

const managers = computed(() => store.state.managersList);

const manager = computed(() => {
  let active = {};

  if (!Cookie.get('activeManager')) {
    active = setActiveManager();
  } else {
    const activeManagerEmail = Cookie.get('activeManager');

    active = managers.value.find((manager) => manager.email === activeManagerEmail)
      || setActiveManager();
  }

  return active;
});

const setActiveManager = () => {
  const getManager = shuffle(managers.value)[0];
  Cookie.set('activeManager', getManager.email);

  return getManager;
};

const close = () => {
  isOpen.value = false;
};
</script>

<style module>
.container {
  position: relative;
}
@media screen and (max-width: 1560px) {
  .container {
    right: 0;
  }
}
.action {
  width: 96px;
  height: 96px;
  background: url('~@/assets/img/phone_container.svg') no-repeat center;
  background-size: cover;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconWrapper {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -3px;
  left: -3px;
  cursor: pointer;
}

.content {
  position: absolute;
  right: 16px;
  bottom: 112px;
  width: 300px;
  border-radius: var(--border-radius-large);
  overflow: hidden;
  filter: var(--phone-shadow);
}

.top {
  background: var(--color-primary);
  padding: 8px 24px 16px;
  color: var(--color-white-100);
}

.bottom {
  background: var(--color-white-100);
  padding: 16px 24px 32px;
  color: var(--color-secondary-darker);
}

.title {
  margin-top: 16px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-size: var(--font-size-small-header);
  line-height: var(--line-height-normal);
  font-weight: var(--font-weight-medium);
}
.titleIcon {
  margin-left: 8px;
}
.bottomTitle {
  font-size: var(--font-size-medium);
  line-height: var(--line-height-normal);
  font-weight: var(--font-weight-normal);
  margin-bottom: 8px;
}
.number {
  font-size: var(--font-size-small-header);
  line-height: var(--line-height-normal);
  font-weight: var(--font-weight-semibold);
  color: var(--color-secondary-darker);
  text-decoration: none;
}
.number:hover {
  color: var(--color-primary);
}
</style>
