import {
  API_SEND_FEEDBACK,
  API_DOWNLOAD_FUNC_CHARACTERISTICS,
  API_DOWNLOAD_PRIVACY_POLICY,
  API_DOWNLOAD_TECH_SUPPORT,
} from '@/services/endpoints';
import RemoteService from '@/services/remote';
import config from '@/services/config';

export default class CarriersService extends RemoteService {
  constructor() {
    super(config.carriersCommunicationServiceHost);
  }

  sendEmailForFeedback(feedback) {
    return this.request({
      method: 'POST',
      url: API_SEND_FEEDBACK,
      data: feedback,
    });
  }

  downloadFuncCharacteristics() {
    return this.request({
      method: 'GET',
      url: API_DOWNLOAD_FUNC_CHARACTERISTICS,
      responseType: 'blob',
    });
  }

  downloadPrivacyPolicy() {
    return this.request({
      method: 'GET',
      url: API_DOWNLOAD_PRIVACY_POLICY,
      responseType: 'blob',
    });
  }

  downloadTechSupport() {
    return this.request({
      method: 'GET',
      url: API_DOWNLOAD_TECH_SUPPORT,
      responseType: 'blob',
    });
  }
}
