<template>
  <ui-modal-with-content
    v-if="order.number"
    :width="786"
    @close="$emit('close')"
  >
    <template #default>
      <div>
        <div :class="[$style.container, $style.mobileHidden]">
          <div :class="$style.header">
            <div :class="$style.row">
              <div>
                <h2 :class="$style.headerTitle">
                  {{ order.number }}
                </h2>
                <div :class="$style.headerNote">
                  {{ createdDate }}
                </div>
              </div>

              <order-price-cell
                :price="order.priceWithTaxes"
                is-card
                is-large-title
              />
            </div>
          </div>

          <div :class="$style.content">
            <div
              v-if="loadingEvents"
              :class="$style.block"
            >
              <div :class="$style.title">
                {{ t('order.route') }}
              </div>

              <div
                v-if="loadingEventsExceptLast"
                :class="$style.cellsList"
              >
                <i :class="$style.line" />

                <div
                  v-for="(event, index) in loadingEventsExceptLast"
                  :key="index"
                  :class="$style.cell"
                >
                  <div :class="$style.cellTitle">
                    {{ getOperationType(event.operationType) }}
                  </div>
                  <div :class="$style.cellDescription">
                    {{ event.location.city.name }},
                    {{ event.location.region.name }}
                  </div>
                  <div :class="$style.cellNote">
                    {{ formatDateTime(event.startDateTime, 'D MMMM, H:mm') }}
                  </div>
                  <div
                    v-if="isAdditionalEvent(index)"
                    :class="$style.badge"
                  />
                </div>
              </div>

              <div
                v-if="loadingEventsLast"
                :class="$style.cellsList"
              >
                <div :class="$style.cell">
                  <div :class="$style.cellTitle">
                    {{ getOperationType(loadingEventsLast.operationType) }}
                  </div>
                  <div :class="$style.cellDescription">
                    {{ loadingEventsLast.location.city.name }},
                    {{ loadingEventsLast.location.region.name }}
                  </div>
                  <div :class="$style.cellNote">
                    {{ formatDateTime(loadingEventsLast.startDateTime, 'D MMMM, H:mm') }}
                  </div>
                </div>
              </div>
            </div>

            <div
              :class="$style.block"
            >
              <div :class="$style.title">
                {{ t('order.information') }}
              </div>

              <dl :class="$style.list">
                <div
                  v-if="cargoType"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.cargoType') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ cargoType }}
                  </dd>
                </div>
                <div
                  v-if="order.cargoWeight"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.cargoWeight') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ order.cargoWeight }} {{ t('order.tons') }}
                  </dd>
                </div>
                <div :class="$style.item">
                  <dt :class="$style.key">
                    {{ t('order.trailerType') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ type }}
                  </dd>
                </div>
                <div
                  v-if="order.tentVolume"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.tentVolume') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ order.tentVolume }} {{ t('order.cubicMeter') }}
                  </dd>
                </div>
                <div
                  v-if="order.loadingType"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.loadingType') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ loadingType }}
                  </dd>
                </div>
                <div
                  v-if="order.temperatureFrom"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.temperatureFrom') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ order.temperatureFrom }}
                  </dd>
                </div>
                <div
                  v-if="order.temperatureTo"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.temperatureTo') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ order.temperatureTo }}
                  </dd>
                </div>
              </dl>
            </div>

            <div
              v-if="showAdditional"
              :class="$style.block"
            >
              <div :class="$style.title">
                {{ t('order.additional') }}
              </div>

              <dl :class="$style.list">
                <div
                  v-if="!!thermographerInRef"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.thermographerInRef') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ thermographerInRef }}
                  </dd>
                </div>
                <div
                  v-if="!!thermograpcleanSemiTrailer"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.thermograpcleanSemiTrailer') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ thermograpcleanSemiTrailer }}
                  </dd>
                </div>
                <div
                  v-if="!!cleaning"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.cleaning') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ cleaning }}
                  </dd>
                </div>
                <div
                  v-if="!!noDamageTent"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.noDamageTent') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ noDamageTent }}
                  </dd>
                </div>
                <div
                  v-if="!!driverHealthCard"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.driverHealthCard') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ driverHealthCard }}
                  </dd>
                </div>
                <div
                  v-if="!!semiTrailerCustomHeight"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.semiTrailerCustomHeight') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ semiTrailerCustomHeight }}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <div :class="$style.footer">
            <ui-button
              :url="href"
              size="large"
            >
              {{ t('order.book') }}
            </ui-button>
          </div>
        </div>

        <div :class="[$style.container, $style.desktopHidden]">
          <div
            :class="$style.header"
            @click="$emit('close')"
          >
            <svg-icon
              :class="$style.icon"
              name="back"
            />
            {{ t('order.dates') }}
          </div>

          <div :class="$style.content">
            <div :class="$style.row">
              <order-number-cell
                :order-number="order.number"
                :order-date="order.date"
                :is-loading="false"
                is-card
              />

              <order-price-cell
                :price="order.priceWithTaxes"
                :is-loading="false"
                is-card
              />
            </div>

            <div
              v-if="order"
              :class="$style.block"
            >
              <div :class="$style.title">
                {{ routeFromFirstToLast }}
              </div>

              <dl :class="$style.list">
                <div
                  v-if="cargoType"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.cargoType') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ cargoType }}
                  </dd>
                </div>
                <div
                  v-if="order.cargoWeight"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.cargoWeight') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ order.cargoWeight }} {{ t('order.tons') }}
                  </dd>
                </div>
                <div
                  v-if="type"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.trailerType') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ type }}
                  </dd>
                </div>
                <div
                  v-if="order.tentVolume"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.tentVolume') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ order.tentVolume }} {{ t('order.cubicMeter') }}
                  </dd>
                </div>
                <div
                  v-if="order.loadingType"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.loadingType') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ loadingType }}
                  </dd>
                </div>
                <div
                  v-if="order.temperatureFrom"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.temperatureFrom') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ order.temperatureFrom }}
                  </dd>
                </div>
                <div
                  v-if="order.temperatureTo"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.temperatureTo') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ order.temperatureTo }}
                  </dd>
                </div>
              </dl>
            </div>

            <div>
              <div :class="$style.title">
                {{ t('order.route') }}
              </div>

              <div
                v-if="loadingEventsExceptLast"
                :class="$style.cellsList"
              >
                <i :class="$style.line" />

                <div
                  v-for="(event, index) in loadingEventsExceptLast"
                  :key="index"
                  :class="$style.cell"
                >
                  <div :class="$style.cellTitle">
                    {{ getOperationType(event.operationType) }}
                  </div>
                  <div :class="$style.cellDescription">
                    {{ event.location.city.name }}, {{ event.location.region.name }}
                  </div>
                  <div :class="$style.cellNote">
                    {{ formatDateTime(event.startDateTime, 'D MMMM, H:mm') }}
                  </div>
                  <div
                    v-if="isAdditionalEvent(index)"
                    :class="$style.badge"
                  />
                </div>
              </div>

              <div
                v-if="loadingEventsLast"
                :class="$style.cellsList"
              >
                <div :class="$style.cell">
                  <div :class="$style.cellTitle">
                    {{ getOperationType(loadingEventsLast.operationType) }}
                  </div>
                  <div :class="$style.cellDescription">
                    {{ loadingEventsLast.location.city.name }},
                    {{ loadingEventsLast.location.region.name }}
                  </div>
                  <div :class="$style.cellNote">
                    {{ formatDateTime(loadingEventsLast.startDateTime, 'D MMMM, H:mm') }}
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="showAdditional"
              :class="$style.block"
            >
              <div :class="$style.title">
                {{ t('order.additional') }}
              </div>

              <dl :class="$style.list">
                <div
                  v-if="!!thermographerInRef"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.thermographerInRef') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ thermographerInRef }}
                  </dd>
                </div>
                <div
                  v-if="!!thermograpcleanSemiTrailer"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.thermograpcleanSemiTrailer') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ thermograpcleanSemiTrailer }}
                  </dd>
                </div>
                <div
                  v-if="!!cleaning"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.cleaning') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ cleaning }}
                  </dd>
                </div>
                <div
                  v-if="!!noDamageTent"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.noDamageTent') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ noDamageTent }}
                  </dd>
                </div>
                <div
                  v-if="!!driverHealthCard"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.driverHealthCard') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ driverHealthCard }}
                  </dd>
                </div>
                <div
                  v-if="!!semiTrailerCustomHeight"
                  :class="$style.item"
                >
                  <dt :class="$style.key">
                    {{ t('order.semiTrailerCustomHeight') }}
                  </dt>
                  <dd :class="$style.value">
                    {{ semiTrailerCustomHeight }}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <div :class="$style.footer">
            <ui-button
              :url="href"
              size="large"
              :class="$style.button"
            >
              {{ t('order.book') }}
            </ui-button>
          </div>
        </div>
      </div>
    </template>
  </ui-modal-with-content>
</template>

<script setup>

import {
  ref,
  reactive,
  computed,
  onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import config from '@/services/config';
import {
  formatDateTime,
  sortArrayByDate,
} from '@/filters/date';
import SvgIcon from '@/kit/SvgIcon.vue';
import UiModalWithContent from '@/kit/UiModalWithContent.vue';
import UiButton from '@/kit/UiButton.vue';
import OrderNumberCell from '@/components/cells/OrderNumberCell.vue';
import OrderPriceCell from '@/components/cells/OrderPriceCell.vue';

import OrderService from '@/services/OrderService';
import { getHTTPError } from '@/utils/errors';

const store = useStore();
const { t } = useI18n();
const toast = useToast();

const props = defineProps({
  orderId: {
    type: String,
    default: null,
  },
});

defineEmits(['close']);

const orderService = new OrderService();

const order = ref({});

const getOrder = async () => {
  try {
    order.value = await orderService.getOrder(props.orderId);
  } catch (e) {
    toast.error(getHTTPError(e));
  }
};

const createdDate = computed(() => {
  if (!order.value?.date) return null;

  return formatDateTime(order.value?.date);
});

const cargoType = computed(() => order.value?.cargoType?.name);

const loadingTypes = reactive({
  BACK: t('order.loadingTypes.BACK'),
  TOP: t('order.loadingTypes.TOP'),
  WITH_CRATE: t('order.loadingTypes.WITH_CRATE'),
  NO_GATE: t('order.loadingTypes.NO_GATE'),
  SIDE: t('order.loadingTypes.SIDE'),
  WITH_SIDES: t('order.loadingTypes.WITH_SIDES'),
  TAIL_LIFT: t('order.loadingTypes.TAIL_LIFT'),
  WITH_THE_REMOVAL_OF_THE_CROSSBARS: t('order.loadingTypes.WITH_THE_REMOVAL_OF_THE_CROSSBARS'),
  SIDE_ON_BOTH_SIDES: t('order.loadingTypes.SIDE_ON_BOTH_SIDES'),
  WITH_RACKS_REMOVED: t('order.loadingTypes.WITH_RACKS_REMOVED'),
  WITH_FULL_COVER: t('order.loadingTypes.WITH_FULL_COVER'),
});

const loadingType = computed(() => (loadingTypes?.[order.value?.loadingType] || null));

const type = computed(() => (order.value.isTent
  ? t('trailerTypes.tent')
  : t('trailerTypes.ref')));

const thermographerInRef = computed(() => (order.value?.thermographerInRef ? t('yes') : null));

const thermograpcleanSemiTrailer = computed(
  () => (order.value?.thermograpcleanSemiTrailer ? t('yes') : null),
);

const cleaning = computed(() => (order.value?.cleaning ? t('yes') : null));

const noDamageTent = computed(() => (order.value?.noDamageTent ? t('yes') : null));

const driverHealthCard = computed(() => (order.value?.driverHealthCard ? t('yes') : null));

const semiTrailerCustomHeight = computed(
  () => (order.value?.semiTrailerCustomHeight ? t('yes') : null),
);

const showAdditional = computed(() => (
  order.value?.thermographerInRef
  || order.value?.thermograpcleanSemiTrailer
  || order.value?.cleaning
  || order.value?.noDamageTent
  || order.value?.driverHealthCard
  || order.value?.semiTrailerCustomHeight
));

const loadingEvents = ref(null);

const loadingEventsExceptLast = computed(() => loadingEvents.value?.slice(0, -1));
const loadingEventsLast = computed(() => loadingEvents.value?.[loadingEvents.value?.length - 1]);

const routeFromFirstToLast = computed(() => {
  if (!loadingEvents.value?.length) {
    return null;
  }
  return `${
    loadingEvents.value?.[0]?.location.city.name
  } - ${
    loadingEvents.value?.[loadingEvents.value.length - 1]?.location.city.name
  }`;
});

const getOrderLoadingEvents = async () => {
  try {
    const events = await orderService.getOrderLoadingEvents({ orderId: props.orderId });
    if (!events || events.length) {
      loadingEvents.value = null;
    }
    const sortingEvents = sortArrayByDate(events);
    loadingEvents.value = sortingEvents;
  } catch (e) {
    toast.error(getHTTPError(e));
  }
};

const getOperationType = (type) => {
  if (!type) return '';

  return type === 1 ? t('orders.loading') : t('orders.unloading');
};

const isAdditionalEvent = (index) => index !== 0 && index !== loadingEvents.value?.length - 1;

const metrikaUser = computed(() => store.state.metrikaUser);
const metrikaParams = computed(
  () => (metrikaUser?.value ? `&userEmail=${metrikaUser?.value?.email}` : ''),
);
const href = computed(() => (
  `${config.host}srm/orders/${props?.orderId}${metrikaParams?.value}`
));

onMounted(() => {
  getOrder();
  getOrderLoadingEvents();
});
</script>

<style module>
  .container {
    background: var(--color-white-100);
    padding-left: 130px;
    color: var(--color-primary-dark-theme);
    max-height: calc(100vh - 120px);
    overflow: auto;
  }

  @media screen and (max-width: 767px) {
    .container {
      margin-left: -16px;
      margin-right: -16px;
      padding: 20px 24px;
      max-width: 100vw;
    }
  }

  .header {
    max-width: 510px;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 767px) {
    .header {
      margin-bottom: 44px;
      font-size: var(--font-size-base);
      line-height: var(--line-height-medium);
      font-weight: var(--font-weight-normal);
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  .headerTitle {
    margin: 0 0 8px;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-small-header);
    line-height: var(--line-height-small);
  }

  .headerNote {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-base);
    line-height: var(--line-height-small);
    color: var(--color-secondary);
  }

  .content {
    max-width: 275px;
  }

  @media screen and (max-width: 767px) {
    .content {
      max-width: none;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 32px;
  }

  .desktopHidden {
    display: none;
  }

  @media screen and (max-width: 767px) {
    .mobileHidden {
      display: none;
    }

    .desktopHidden {
      display: block;
    }
  }

  .footer {
    max-width: 510px;
    margin-top: 18px;
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 767px) {
    .footer {
      margin-top: 32px;
      justify-content: flex-start;
    }

    .button {
      width: 100%;
    }
  }

  .title {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-small-header);
    line-height: var(--line-height-medium);
    margin-bottom: 16px;
  }

  .block:not(:last-child) {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 767px) {
    .block:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  .list {
    margin: 0;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-base);
    line-height: var(--line-height-xsmall);
  }

  .item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .item:not(:last-child) {
    margin-bottom: 16px;
  }

  .key {
    color: var(--color-secondary);
    min-width: 100px;
  }

  .value {
    color: var(--color-primary-dark-theme);
    text-align: right;
  }

  .cellsList {
    position: relative;
    margin-left: -26px;
    padding-left: 26px;
  }

  .cellsList:not(:last-child) {
    padding-bottom: 26px;
  }

  .cellsList:last-child {
    top: -3px;
  }

  .cellsList:last-child:before {
    content: '';
    position: absolute;
    top: 0;
    left: -4.5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--color-primary);
  }

  @media screen and (max-width: 767px) {
    .cellsList {
      margin-left: 0;
    }
    .cellsList:last-child {
      margin-bottom: 26px;
    }
  }

  .line {
    position: absolute;
    left: 0;
    border-left: 1px dashed var(--color-secondary);
    top: 6px;
    bottom: 0;
    width: 0;
  }

  .line::before {
    content: '';
    position: absolute;
    left: -5.5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--color-primary);
  }

  .line::before {
    top: 0;
  }

  .line::after {
    bottom: 0;
  }

  .cell {
    position: relative;
  }

  .cell:not(:last-child) {
    margin-bottom: 26px;
  }

  .badge {
    position: absolute;
    left: -28px;
    top: 5px;
    width: 5px;
    height: 5px;
    background: var(--color-secondary);
    border-radius: var(--border-radius-round);
  }

  .cellTitle {
    margin-bottom: 8px;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-base);
    line-height: var(--line-height-medium);
    text-transform: uppercase;
    color: var(--color-secondary);
  }

  @media screen and (max-width: 767px) {
    .cellTitle {
      font-size: var(--font-size-tiny);
    }
  }

  .cellDescription {
    margin-bottom: 8px;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
    color: var(--color-secondary-darker);
  }

  @media screen and (max-width: 767px) {
    .cellDescription {
      font-size: var(--font-size-base);
    }
  }

  .cellNote {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-xsmall);
    line-height: var(--line-height-medium);
    color: var(--color-secondary);
  }

  .icon {
    position: relative;
    top: 2px;
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: 767px) {
    .icon {
      top: 0;
    }
  }
</style>
