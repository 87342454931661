import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

import config from '@/services/config';
import { getHTTPError } from '@/utils/errors';
import SpreadsheetService from '@/services/spreadsheet';

const useSpreadsheetManagers = () => {
  const store = useStore();
  const toast = useToast();

  const spreadsheetService = new SpreadsheetService();

  const getManagersList = async () => {
    try {
      const managersList = await spreadsheetService
        .getSpreadSheetTable(config.managersSpreadsheetId, 'Лист1');

      if (!managersList?.length) return;

      store.commit('setManagers', managersList);
    } catch (e) {
      toast.error(getHTTPError(e));
    }
  };

  return {
    getManagersList,
  };
};

export default useSpreadsheetManagers;
