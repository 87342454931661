<template>
  <div :class="{ [$style.dark]: isDarkTheme }">
    <div :class="$style.container">
      <div :class="$style.content">
        <div :class="$style.title">
          {{ t('orders.form.title') }}
        </div>
        <div :class="$style.text">
          {{ t('orders.form.text') }}
        </div>
      </div>
      <div :class="$style.form">
        <ui-form-group
          v-slot="{ id, isInvalid }"
          :field="$v.company"
          :label="t('orders.form.company')"
        >
          <ui-input-text
            :id="id"
            v-model="$v.company.$model"
            :class="$style.input"
            :is-invalid="isInvalid"
            :placeholder="t('orders.form.company')"
          />
        </ui-form-group>
        <ui-form-group
          v-slot="{ id, isInvalid }"
          :field="$v.phone"
          :label="t('orders.form.phone')"
        >
          <ui-input-text
            :id="id"
            v-model="$v.phone.$model"
            :class="$style.input"
            :is-invalid="isInvalid"
            :mask="phoneMask"
          />
        </ui-form-group>
        <ui-form-group
          v-slot="{ id, isInvalid }"
          :field="$v.description"
          :label="t('orders.form.direction')"
        >
          <ui-textarea
            :id="id"
            v-model="$v.description.$model"
            :class="[$style.input, $style.textarea]"
            :is-invalid="isInvalid"
            :resize="false"
            maxlength="300"
          />
        </ui-form-group>
        <div :class="$style.action">
          <ui-button
            :is-loading="isLoading"
            color="secondary"
            size="large"
            @click="onSubmit"
          >
            {{ t('orders.form.submit') }}
          </ui-button>
        </div>
      </div>

      <div
        :class="$style.close"
        @click="$emit('close')"
      >
        <svg-icon
          :class="$style.icon"
          name="close"
        />
      </div>
    </div>

    <ui-modal-with-content
      v-if="isModalVisible"
      :width="694"
      overlay-color="dark"
      @close="closeModal"
    >
      <template #default>
        <div>
          <div :class="$style.dialog">
            <p>{{ t('submitModal.text1') }}</p>
            <p>{{ t('submitModal.text2') }}</p>

            <ui-button
              :class="$style.dialogButton"
              color="primary"
              size="large"
              @click="closeModal"
            >
              {{ t('submitModal.submit') }}
            </ui-button>
          </div>
        </div>
      </template>
    </ui-modal-with-content>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  computed,
} from 'vue';

import {
  required,
  minLength,
  maxLength,
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';

import config from '@/services/config';
import UiFormGroup from '@/kit/UiFormGroup.vue';
import UiInputText from '@/kit/UiInputText.vue';
import UiTextarea from '@/kit/UiTextarea.vue';
import UiButton from '@/kit/UiButton.vue';
import UiModalWithContent from '@/kit/UiModalWithContent.vue';
import SvgIcon from '@/kit/SvgIcon.vue';

import phoneMask from '@/utils/masks';
import CarriersService from '@/services/CarriersService';
import { getHTTPError } from '@/utils/errors';

const { t } = useI18n();
const toast = useToast();
const carriersService = new CarriersService();

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'light',
    validate: (value) => ['dark', 'light'].includes(value),
  },
});

const emit = defineEmits(['close']);

const isDarkTheme = computed(() => props.theme === config.themes.DARK);

const isLoading = ref(false);
const isModalVisible = ref(false);

const state = reactive({
  phone: null,
  description: null,
  company: null,
});

const validations = {
  phone: {
    required,
    minLength: minLength(11),
  },
  company: {
    required,
  },
  description: {
    maxLength: maxLength(300),
  },
};

const $v = useVuelidate(validations, state);

const onSubmit = async () => {
  $v.value.$touch();

  if ($v.value.$invalid) return;

  isLoading.value = true;

  try {
    await carriersService.sendEmailForFeedback({
      phoneNumber: state.phone,
      companyName: state.company,
      desiredDestinationAndDate: state.description,
    });
    isModalVisible.value = true;
  } catch (e) {
    toast.error(getHTTPError(e));
  }

  isLoading.value = false;
};

const closeModal = () => {
  isModalVisible.value = false;
  emit('close');
};
</script>

<style module>
.container {
  position: relative;
  margin-bottom: 32px;
  padding: 48px 128px 32px;
  background: var(--color-white-100);
  border: 1px solid var(--color-white-130);
  border-radius: var(--border-radius-large);
  box-shadow: var(--form-shadow);
  display: flex;
  justify-content: space-between;
}
.dark .container {
  box-shadow: none;
}

@media screen and (max-width: 767px) {
  .container {
    margin: 0 -16px 16px;
    box-shadow: none;
    border: none;
    border-radius: 0;
    padding: 48px 24px 24px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
  }
}
.content {
  width: 442px;
}
.form {
  width: 445px;
}
@media screen and (max-width: 767px) {
  .content {
    width: 100%;
  }
  .form {
    width: 100%;
  }
}
.title {
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxlarge-header);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-header);
  margin-bottom: 16px;
}
@media screen and (max-width: 767px) {
  .title {
    font-size: var(--font-size-small-header);
    line-height: var(--line-height-medium);
    margin-bottom: 8px;
  }
}
.text {
  font-size: var(--font-size-medium-header);
  line-height: var(--line-height-large);
}
@media screen and (max-width: 767px) {
  .text {
    font-size: var(--font-size-xsmall);
    line-height: var(--line-height-xxlarge);
  }
}
.textarea {
  height: 80px;
}
.input input::placeholder {
  color: var(--color-placeholder);
}
.action {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
.close {
  position: absolute;
  top: 14px;
  right: 14px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  display: flex;
  color: var(--color-secondary);
}
.icon {
  width: 22px;
  height: 22px;
}

.dialog {
  padding: 16px 70px 0;
}

@media screen and (max-width: 767px) {
  .dialog {
    padding: 0;
  }
}

.dialog p {
  font-size: var(--font-size-medium-header);
  line-height: var(--line-height-medium);
  color: var(--color-secondary-darker);
}
.dialog p:not(:last-child) {
  margin-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .dialog p {
    font-size: var(--font-size-base);
  }
}

.dialogButton {
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  .dialogButton {
    margin-top: 24px;
  }
}
</style>
