export function getGenericError(errorCode) {
  return `
    Что-то пошло не так. Пожалуйста, повторите попытку позже. ${
  errorCode ? `Код ошибки: ${errorCode}` : ''
}
  `.trim();
}

export function getHTTPError(error) {
  let errorWithMessage = '';
  const axiosError = error;

  if (axiosError.response) {
    errorWithMessage = axiosError.response.data?.userMessage
      || axiosError.response.data?.error
      || getGenericError(axiosError.response.status);
  } else {
    errorWithMessage = `${axiosError.message}`;
    console.error(axiosError);
  }

  return errorWithMessage;
}
