import config from '@/services/config';

const GSheetReader = require('g-sheets-api');

const options = {
  apiKey: config?.googleApiKey,
  sheetId: config?.spreadsheetId,
  sheetNumber: 1,
  returnAllResults: false,
};

export default class SpreadsheetService {
  getSpreadSheetTable = async (sheetId = options.sheetId, sheetName = '') => {
    const gSheetReader = new Promise((resolve) => {
      GSheetReader(
        {
          ...options,
          sheetId,
          sheetName,
        },
        (results) => {
          resolve(results);
        },
      );
    });

    return gSheetReader;
  };
}
