<template>
  <a
    v-tooltip="tooltipOptions"
    :class="$style.container"
    :href="telegramLink"
    target="_blank"
  >
    <div :class="$style.action">
      <div :class="$style.iconWrapper">
        <svg-icon
          name="telegram"
          :class="$style.icon"
        />
      </div>
    </div>

  </a>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import {
  computed,
} from 'vue';
import SvgIcon from '@/kit/SvgIcon.vue';
import config from '@/services/config';

const { t } = useI18n();

const tooltipOptions = computed(() => ({
  title: t('telegramTooltip.title'),
  text: t('telegramTooltip.text'),
  position: 'left',
}));

const telegramLink = config.telegramNotificationBot;
</script>

<style module>
.container {
  display: block;
}
@media screen and (max-width: 1560px) {
  .container {
    right: 0;
  }
}
.action {
  width: 96px;
  height: 96px;
  background: url('~@/assets/img/phone_container.svg') no-repeat center;
  background-size: cover;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconWrapper {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -3px;
  left: -3px;
  cursor: pointer;
}
.icon {
  color: var(--color-white-100);
  width: 32px;
  height: 32px;
}
</style>
