import dayjs from 'dayjs';

/**
 * Возвращает дату со смещением относительно текущего часового пояса
 * @param date - дата
 */
// eslint-disable-next-line import/prefer-default-export
export function getDateForCurrentTimeZone(date) {
  return dayjs(date).add(dayjs().utcOffset(), 'minute').toDate();
}
