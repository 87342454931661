<template>
  <div :class="$style.container">
    <date-picker
      ref="datepicker"
      :value="modelValue"
      :class="{
        [$style.datepicker]: true,
        filled: !!modelValue,
      }"
      :inline="inline"
      :input-class="{}"
      :popup-class="$style.datepickerPopup"
      :open="isOpened"
      :disabled-date="disabledDate"
      :disabled-time="disabledTime"
      :format="format"
      :multiple="multiple"
      :separator="true"
      :range="range"
      :clearable="clearable"
      :type="type"
      :disabled="disabled"
      :placeholder="placeholder"
      v-bind="$attrs"
      :confirm="confirmByButton"
      :confirm-text="confirmButtonText"
      @update:value="onUpdate"
      @close="close"
      @open="open"
    >
      <template #input="attrs">
        <slot name="input">
          <ui-input-text
            v-model="attrs.value"
            v-bind="attrs"
            :is-invalid="isInvalid"
            :data-qa="qaKey"
          />
        </slot>
      </template>
      <template #icon-calendar>
        <svg-icon
          name="calendar"
          :class="$style.icon"
        />
      </template>

      <template #icon-clear>
        <div>
          <svg-icon
            name="cancel"
            :class="$style.icon"
          />
        </div>
      </template>

      <template #header>
        <div :class="$style.header">
          <svg-icon
            name="close"
            :class="$style.iconClose"
            @click="close"
          />
        </div>
      </template>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import 'vue-datepicker-next/locale/ru';
import SvgIcon from '@/kit/SvgIcon.vue';
import UiInputText from '@/kit/UiInputText.vue';

export default {
  name: 'UiDatepicker',

  components: {
    DatePicker,
    SvgIcon,
    UiInputText,
  },

  props: {
    isInvalid: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    appearance: {
      type: String,
      default: 'input',
      validator: (value) => ['input', 'clean'].includes(value),
    },
    modelValue: {
      type: [String, Date, Number, Array],
      default: null,
    },
    format: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    start: {
      type: Date,
      default: null,
    },
    end: {
      type: Date,
      default: null,
    },
    range: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'date',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    qaKey: {
      type: String,
      default: null,
    },
    dayInWeek: {
      type: Number,
      default: null,
    },
    confirmByButton: {
      type: Boolean,
      default: false,
    },
    confirmButtonText: {
      type: String,
      default: 'OK',
    },
  },

  emits: [
    'update:value',
    'update:model-value',
    'close',
    'open',
  ],

  data() {
    return {
      isOpened: false,
    };
  },

  methods: {
    onUpdate($event) {
      this.$emit('update:model-value', $event);
    },
    disabledDate(date) {
      if (this.dayInWeek) {
        const day = this.dayInWeek === 7 ? 0 : this.dayInWeek;
        return date.getDay() !== day;
      }

      if (this.start) {
        return date < new Date(this.start).setHours(0, 0, 0, 0);
      }

      if (this.end) {
        return date > new Date(this.end).setHours(0, 0, 0, 0);
      }

      return false;
    },
    disabledTime(time) {
      if (this.start) {
        return time < this.start;
      }

      if (this.end) {
        return time > this.end;
      }

      return false;
    },
    open() {
      this.$emit('open');
      this.isOpened = true;
    },
    close() {
      this.$emit('close');
      this.isOpened = false;
    },
  },
};
</script>

<style module>
.container {
  position: relative;
  display: flex;
  width: 100%;
  flex: 1;
  height: 100%;
}

:global .mx-datepicker svg {
  width: 22px;
  height: 22px;
  color: var(--color-black-67);
}

:global .mx-datepicker-inline .mx-table {
  table-layout: auto;
}

:global .mx-datepicker-inline .mx-calendar {
  width: 360px;
}

:global .mx-datepicker-inline .mx-datepicker-main {
  background: transparent;
  border: none;
}

:global .mx-datepicker-inline .mx-calendar-content .cell {
  min-width: 48px;
}

:global .mx-table-date th {
  color: var(--color-secondary);
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-xlarge);
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
}
:global .mx-table-date td {
  font-size: var(--font-size-base);
  line-height: var(--line-height-xlarge);
  font-weight: var(--font-weight-normal);
  color: var(--color-secondary-darker);
}

:global .mx-calendar {
  width: 320px;
}

:global .mx-calendar-content .cell:not(.hover-in-range):not(.in-range):hover,
:global .mx-calendar-content .cell.active {
  background-color: var(--color-primary);
  color: var(--color-white-100);
  border-radius: var(--border-radius-base);
}
:global .mx-calendar-content .cell.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-primary-light);
  z-index: -1;
}

:global .mx-btn-current-month {
  text-transform: capitalize;
}

:global .mx-calendar-content .cell:hover {
  background-color: var(--color-primary);
  color: var(--color-white-100);
}

:global .mx-datepicker-popup {
  box-shadow: none;
}

:global .mx-calendar-content {
  height: 270px;
}

:global .mx-calendar-content .cell {
  position: relative;
}

:global .mx-calendar + .mx-calendar {
  border: none;
}

:global .mx-calendar-content .cell.hover-in-range,
:global .mx-calendar-content .cell.in-range {
  background-color: var(--color-primary-light);
  color: var(--color-secondary-darker);
}

:global .mx-table-date .cell.not-current-month {
  opacity: 0;
  visibility: hidden;
}
:global .mx-table-date .cell.not-current-month.in-range,
:global .mx-table-date .cell.not-current-month.hover-in-range {
  background-color: var(--color-primary-light);
}
:global .mx-table-date .today {
  color: var(--color-primary);
}

:global .mx-calendar {
  padding: 32px 16px 32px 32px;
}

:global .mx-calendar:not(:first-child) {
  padding: 32px 32px 32px 16px;
}

:global .mx-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 20px;
}
:global .filled .mx-input-wrapper {
  color: var(--color-secondary-darker);
}
:global .mx-input-wrapper span {
  white-space: nowrap;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
}

:global .mx-icon-calendar,
:global .mx-icon-clear {
  right: 0;
}

:global .mx-icon-double-left,
:global .mx-icon-double-right {
  display: none;
}

:global .mx-input-wrapper .mx-icon-clear {
  display: block;
}

:global .mx-input-wrapper .mx-icon-clear + .mx-icon-calendar {
  display: none;
}

:global .mx-calendar-header-label .mx-btn-text {
  color: var(--color-secondary-darker);
  font-family: var(--font-family);
  font-size: var(--font-size-medium);
  line-height: var(--line-height-large);
  font-weight: var(--font-weight-semibold);
}

:global .mx-btn-icon-left,
:global .mx-btn-icon-right {
  background: url('~@/assets/img/calendar_arrow.svg') no-repeat center;
  width: 20px;
  height: 28px;
  display: block;
}
:global .mx-btn-icon-right {
  transform: rotate(180deg);
}

:global .mx-btn-icon-left i,
:global .mx-btn-icon-right i {
  display: none;
}
:global .mx-table-date td,
:global .mx-table-date th {
  height: auto;
  padding: 10px;
}

.datepicker {
  width: 100%;
  display: flex;
  flex: 1;
}

.datepickerPopup {
  margin-top: 6px;
  border-radius: var(--border-radius-large);
  font-family: var(--font-family);
}

.header {
  display: none;
}

:global .mx-datepicker-header {
  padding: 0;
  border: none;
}

@media screen and (max-width: 767px) {
  :global .mx-calendar {
    width: 100%;
    padding: 24px;
  }
  .datepickerPopup {
    position: fixed !important;
    width: calc(100vw - 32px) !important;
    left: 16px !important;
    top: calc(50vh - 335px) !important;
  }

  .header {
    display: block;
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .iconClose {
    width: 24px;
    height: 24px;
  }

  :global .mx-datepicker-content {
    position: relative;
    padding-top: 20px;
  }

  :global .mx-input-wrapper span {
    padding-right: 20px;
  }
}
</style>
