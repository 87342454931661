export function downloadFile(file, name) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.cssText = 'display: none';
  const fileIsNotEmpty = file instanceof File || file instanceof Blob;
  if (!fileIsNotEmpty) return;
  a.href = window.URL.createObjectURL(file);
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(a.href);
}

export default {
  downloadFile,
};
