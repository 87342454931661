export default {
  modelValue: {
    type: [String, Number],
    default: null,
  },
  id: {
    type: String,
    default: null,
  },
  isInvalid: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  appearance: {
    type: String,
    default: 'filled',
    validate: (value) => ['filled', 'clean'].includes(value),
  },
  leftIcon: {
    type: String,
    default: null,
  },
  rightIcon: {
    type: String,
    default: null,
  },
  mask: {
    type: Object,
    default: null,
  },
};
