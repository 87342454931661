<template>
  <transition name="fade">
    <div
      v-show="scY > scrollLevel"
      :class="$style.fixed"
      @click="scrollContentTop"
    >
      <svg-icon
        :class="$style.icon"
        name="arrow_up"
        width="24px"
        height="24px"
      />
    </div>
  </transition>
</template>

<script setup>
import {
  ref,
  unref,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import useWindowScroll from '@/composable/window';
import SvgIcon from '@/kit/SvgIcon.vue';

const { scrollContentTop } = useWindowScroll();

const scTimer = ref(0);
const scY = ref(0);
const scrollLevel = ref(350);

const handleScroll = () => {
  if (unref(scTimer)) return;

  scTimer.value = setTimeout(() => {
    scY.value = window.scrollY;
    clearTimeout(unref(scTimer));
    scTimer.value = 0;
  }, 100);
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style module>
.fixed {
  cursor: pointer;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-white-100);
  box-shadow: var(--multiselect-shadow-small);
  border-radius: 50%;
}
@media screen and (max-width: 1560px) {
  .fixed {
    right: 16px;
  }
}
.icon {
  width: 24px;
  height: 24px;
  color: var(--color-secondary-darker);
}
</style>
