export default {
  host: process.env.VUE_APP_HOST,
  SRMOrderHost: process.env.VUE_APP_API_ORDER_SRM_HOST,
  carriersCommunicationServiceHost: process.env.VUE_APP_API_CARRIERS_COMMUNICATION_HOST,
  requestTimeout: Number(process.env.VUE_APP_REQUEST_TIMEOUT),
  version: process.env.VUE_APP_VERSION,
  yandexMetrikaId: process.env.VUE_APP_YANDEX_METRIKA_ID,
  yandexMetrikaIdV2: process.env.VUE_APP_YANDEX_METRIKA_ID_V2,
  spreadsheetId: process.env.VUE_APP_SPREADSHEET_ID,
  managersSpreadsheetId: process.env.VUE_APP_MANAGERS_SPREADSHEET_ID,
  googleApiKey: process.env.VUE_APP_GOOGLE_API_KEY,
  telegramNotificationBot: process.env.VUE_APP_TELEGRAM_NOTIFICATIONS_BOT,
  icqNotificationBot: process.env.VUE_APP_ICQ_NOTIFICATIONS_BOT,
  themes: {
    DARK: 'dark',
    LIGHT: 'light',
  },
  theme: '',
};
