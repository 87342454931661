<template>
  <nav
    :class="{
      [$style.container]: true,
      [$style.loading]: isLoading,
    }"
    aria-label="Page navigation"
  >
    <div
      v-if="page > 1"
      :class="$style.block"
    >
      <ui-button
        appearance="icon"
        color="primary"
        size="medium"
        :prepend-icon="isLoading ? null : 'arrow_down'"
        data-qa="page-prev"
        :class="$style.button"
        :icon-class-name="$style.leftIcon"
        @click.prevent="prev"
      >
        <template v-if="!isLoading">
          {{ t('pagination.prev') }}
        </template>

        <ui-skeleton
          v-else
          width="77px"
          height="18px"
          :rounded="true"
        />
      </ui-button>
    </div>

    <ul :class="$style.pagination">
      <li
        v-for="n in pages"
        :key="n"
        :class="$style.pageItem"
        :data-qa="`page-${n}`"
      >
        <a
          :class="{
            [$style.pageLink]: true,
            [$style.isActive]: n === page,
          }"
          :href="'#page-' + n"
          @click.prevent="change(n)"
        >
          <template v-if="!isLoading">
            {{ n }}
          </template>

          <ui-skeleton
            v-else
            :class="$style.skeleton"
            width="17px"
            height="18px"
            :rounded="true"
          />
        </a>
      </li>
    </ul>

    <ui-button
      v-if="!isLastPage"
      appearance="icon"
      color="primary"
      size="medium"
      :append-icon="isLoading ? null : 'arrow_down'"
      data-qa="page-next"
      :class="$style.button"
      :icon-class-name="$style.rightIcon"
      @click.prevent="next"
    >
      <template v-if="!isLoading">
        {{ t('pagination.next') }}
      </template>

      <ui-skeleton
        v-else
        width="77px"
        height="18px"
        :rounded="true"
      />
    </ui-button>
  </nav>
</template>

<script>
import { useI18n } from 'vue-i18n';
import UiButton from '@/kit/UiButton.vue';
import UiSkeleton from '@/kit/UiSkeleton.vue';

function* range(start, length) {
  for (let i = 0; i < length; i += 1) {
    yield start + i;
  }
}

export default {
  name: 'UiPagination',

  components: {
    UiButton,
    UiSkeleton,
  },

  props: {
    totalPages: {
      type: Number,
      default: null,
    },
    page: {
      type: Number,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:page'],

  computed: {
    t() {
      return useI18n().t;
    },
    pages() {
      return [
        ...range(
          Math.max(Math.min(this.page, this.totalPages - 2), 3) - 2,
          Math.min(this.totalPages, 5),
        ),
      ];
    },

    isLastPage() {
      return this.page === this.totalPages;
    },
  },

  methods: {
    change(page) {
      if (this.isLoading) {
        return;
      }
      this.$emit('update:page', page);
    },

    next() {
      if (this.isLoading) {
        return;
      }
      this.$emit('update:page', Math.min(this.page + 1, this.totalPages));
    },

    prev() {
      if (this.isLoading) {
        return;
      }
      this.$emit('update:page', Math.max(this.page - 1, 1));
    },
  },
};
</script>

<style module>
.container {
  display: flex;
  gap: 16px;
}

@media screen and (max-width: 767px) {
  .container {
    justify-content: space-between;
    width: 100%;
  }
}

.block {
  position: relative;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  margin: 0;
}

.pageItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.pageLink {
  position: relative;
  display: block;

  background: var(--color-white-100);
  border: 1px solid var(--color-secondary-light);
  border-right: 0;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height-medium);
  color: var(--color-primary);
  text-decoration: none;
  text-align: center;
  min-width: 32px;
  padding: 5px 4px;
  height: 32px;
}

.pageItem:last-child .pageLink {
  border-right: 1px solid var(--color-secondary-light);
}

.pageItem:first-child .pageLink {
  border-top-left-radius: var(--border-radius-base);
  border-bottom-left-radius: var(--border-radius-base);
}
.pageItem:last-child .pageLink {
  border-top-right-radius: var(--border-radius-base);
  border-bottom-right-radius: var(--border-radius-base);
}

.pageLink:hover,
.pageLink:focus,
.pageLink:active {
  color: var(--color-primary);
  background: var(--color-white-100);
}
.pageLink.disabled {
  background: var(--color-secondary-lightest);
  border: 1px solid var(--color-secondary-light);
  cursor: not-allowed;
}
.pageLink:hover,
.pageItem:last-child .pageLink:hover {
  border-color: var(--color-primary);
}

.pageLink.isActive,
.pageItem:last-child .pageLink.isActive {
  background: var(--color-primary);
  color: var(--color-white-100);
  border-color: var(--color-primary);
}

@media screen and (max-width: 767px) {
  .pageItem:first-child,
  .pageItem:last-child {
    display: none;
  }

  .pageItem:nth-last-child(2) .pageLink {
    border-right: 1px solid var(--color-secondary-light);
  }

  .pageItem:nth-child(2) .pageLink {
    border-top-left-radius: var(--border-radius-base);
    border-bottom-left-radius: var(--border-radius-base);
  }
  .pageItem:nth-last-child(2) .pageLink {
    border-top-right-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);
  }

  .pageItem:nth-last-child(2) .pageLink:hover {
    border-color: var(--color-primary);
  }

  .pageItem:nth-last-child(2) .pageLink.isActive {
    background: var(--color-primary);
    color: var(--color-white-100);
    border-color: var(--color-primary);
  }
}

.loading .pageLink.isActive {
  background: var(--color-white-100);
  color: var(--color-white-100);
}

.leftIcon {
  transform: rotate(90deg);
}

.rightIcon {
  transform: rotate(-90deg);
}
.container .button:not(:disabled) {
  background-color: var(--color-white-100);
  border: 1px solid var(--color-secondary-light);
  border-radius: var(--border-radius-base);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height-medium);
  color: var(--color-primary);
}
.button:not(:disabled):hover,
.button:not(:disabled):focus,
.button:not(:disabled):active {
  color: var(--color-primary);
  background: var(--color-white-100);
}
.button:disabled {
  background: var(--color-secondary-lightest);
  border: 1px solid var(--color-secondary-light);
  cursor: not-allowed;
}
.loading .button:not(:disabled):hover,
.loading .button:not(:disabled):focus,
.loading .button:not(:disabled):active {
  border: 1px solid var(--color-secondary-light);
  cursor: wait;
}

.skeleton {
  margin: auto;
}
</style>
