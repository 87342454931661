<script>
import {
  h,
  useCssModule,
} from 'vue';
import SvgIcon from '@/kit/SvgIcon.vue';

const UiLabel = (props, context) => {
  const style = useCssModule();

  const {
    attrs,
  } = context;
  const containerData = {
    class: [
      style.container,
      attrs.class,
    ],
  };

  const iconData = {
    class: style.icon,
    props: {
      name: props.prependIcon,
    },
  };

  const labelData = {
    class: style.label,
    attrs: {
      for: props.for,
      'aria-hidden': !props.for,
    },
  };

  return h(
    'div',
    containerData,
    [
      props.prependIcon ? h(SvgIcon, iconData, null) : null,
      h('label', labelData, context.slots.default()),
    ].filter(
      (element) => element !== null,
    ),
  );
};

UiLabel.props = {
  prependIcon: {
    type: String,
    default: null,
  },
  for: {
    type: String,
    default: null,
  },
};

export default UiLabel;
</script>

<style module>
.container {
  height: 20px;
  display: flex;
  flex-flow: row;
  align-items: center;
  color: var(--color-secondary-darker);
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.label {
  display: block;
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
}

@media screen and (max-width: 767px) {
  .label {
    font-size: var(--font-size-base);
  }
}
</style>
