export default {
  mask: '+{7} (000) 000-00-00',
  padFractionalZeros: false,
  prepare: (appended, masked) => {
    if (appended === '8' && masked.value === '') {
      return '';
    }

    return appended;
  },
};
