<template>
  <div :class="classes">
    <div
      v-if="!isLoading"
      @click="isLoadingEventsVisible = true"
    >
      <div :class="$style.title">
        {{ orderNumber }}
      </div>

      <div :class="$style.date">
        <div>{{ t('orders.created') }}:</div>
        {{ formatDateTime(orderDate) }}
      </div>
    </div>

    <template v-else>
      <ui-skeleton
        v-if="isLoading"
        :class="$style.skeleton"
        width="77px"
        height="18px"
        :rounded="true"
      />
      <ui-skeleton
        v-if="isLoading"
        :class="$style.skeleton"
        width="87px"
        height="18px"
        :rounded="true"
      />
    </template>

    <cell-info
      v-if="isLoadingEventsVisible && showInfo"
      @close="isLoadingEventsVisible = false"
    >
      <div>
        {{ t('table.hint') }}
      </div>
    </cell-info>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  useCssModule,
  unref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { formatDateTime } from '@/filters/date';
import UiSkeleton from '@/kit/UiSkeleton.vue';
import CellInfo from '@/components/cells/CellInfo.vue';

const style = useCssModule();
const { t } = useI18n();

const props = defineProps({
  orderNumber: {
    type: String,
    default: null,
  },
  orderDate: {
    type: String,
    default: null,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  showInfo: {
    type: Boolean,
    default: false,
  },
  isCard: {
    type: Boolean,
    default: false,
  },
});

const isLoadingEventsVisible = ref(true);

const classes = computed(() => ({
  [style.container]: true,
  [style.card]: unref(props.isCard),
}));
</script>

<style module>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 56px;
  min-width: 100px;
  justify-content: center;
}
.card.container {
  justify-content: flex-start;
  min-height: auto;
}
.title {
  position: relative;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-medium);
  color: var(--color-secondary-darker);
  margin-bottom: 4px;
}

.date {
  position: relative;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height-medium);
  color: var(--color-secondary);
}

.card .date {
  font-size: var(--font-size-xsmall);
  line-height: var(--line-height-medium);
}

.skeleton:not(:last-child) {
  margin-bottom: 4px;
}
</style>
