<template>
  <teleport to="#modal">
    <div
      :class="[$style.container, $style[overlayColor]]"
      :style="{ backgroundColor: overlayColor }"
      @click="onClose"
    >
      <slot name="close" />
      <slot />
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'UiModal',

  props: {
    overlayColor: {
      type: String,
      default: 'light',
      validate: (value) => [
        'light',
        'dark',
      ].includes(value),
    },
    closeOnClickOverlay: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['close'],

  mounted() {
    global.document.body.classList.add(this.$style.openModal);
  },

  unmounted() {
    global.document.body.classList.remove(this.$style.openModal);
  },

  methods: {
    onClose() {
      if (this.closeOnClickOverlay) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style module>
.container {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: var(--z-index-modal);
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container.light {
  background-color: var(--color-overlay-light);
}

.container.dark {
  background-color: var(--color-overlay-dark);
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
}

.openModal {
  overflow: hidden;
}
</style>
