<template>
  <div>
    <ui-form-group
      v-slot="{ id, isInvalid }"
      :field="$v.company"
      :label="t('orders.form.company')"
    >
      <ui-input-text
        :id="id"
        v-model="$v.company.$model"
        :class="$style.input"
        :is-invalid="isInvalid"
        :placeholder="t('orders.form.company')"
      />
    </ui-form-group>

    <ui-form-group
      v-slot="{ id, isInvalid }"
      :field="$v.phone"
      :label="t('orders.form.phone')"
    >
      <ui-input-text
        :id="id"
        v-model="$v.phone.$model"
        :class="$style.input"
        :is-invalid="isInvalid"
        :mask="phoneMask"
      />
    </ui-form-group>

    <ui-form-group
      v-slot="{ id, isInvalid }"
      :field="$v.description"
      :label="t('orders.form.direction')"
    >
      <ui-textarea
        :id="id"
        v-model="$v.description.$model"
        :class="[$style.input, $style.textarea]"
        :is-invalid="isInvalid"
        :placeholder="t('orders.form.directionPlaceholder')"
        :resize="false"
        maxlength="300"
      />
    </ui-form-group>
    <div :class="$style.action">
      <ui-button
        :is-loading="isLoading"
        :class="$style.button"
        @click="onSubmit"
      >
        {{ t('orders.form.submit') }}
      </ui-button>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
} from 'vue';
import { useI18n } from 'vue-i18n';

import {
  required,
  minLength,
  maxLength,
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

import UiFormGroup from '@/kit/UiFormGroup.vue';
import UiInputText from '@/kit/UiInputText.vue';
import UiTextarea from '@/kit/UiTextarea.vue';
import UiButton from '@/kit/UiButton.vue';

import phoneMask from '@/utils/masks';

defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['submit']);
const { t } = useI18n();

const state = reactive({
  phone: null,
  company: null,
  description: null,
});

const validations = {
  phone: {
    required,
    minLength: minLength(11),
  },
  company: {
    required,
  },
  description: {
    maxLength: maxLength(300),
  },
};

const $v = useVuelidate(validations, state);

const onSubmit = () => {
  $v.value.$touch();

  if ($v.value.$invalid) {
    return;
  }

  emit('submit', {
    phoneNumber: state.phone,
    companyName: state.company,
    desiredDestinationAndDate: state.description,
  });
};
</script>

<style module>
.action {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.button {
  height: 40px;
  padding: 10px 16px;
  border-radius: var(--border-radius-base);
  font-size: var(--font-size-medium-header);
  line-height: var(--line-height-medium);
}

@media screen and (max-width: 767px) {
  .button {
    font-size: var(--font-size-medium);
    line-height: var(--line-height-small);
  }
}

.textarea {
  height: 74px;
}
.input input::placeholder {
  color: var(--color-placeholder);
}
</style>
