<template>
  <ui-multi-select
    :model-value="tentType"
    label="name"
    track-by="name"
    :placeholder="placeholder || ''"
    :show-clear-icon="false"
    :options="tentTypesList"
    @update:model-value="onUpdate"
  />
</template>

<script setup>
import {
  ref,
  reactive,
  computed,
} from 'vue';
import UiMultiSelect from '@/kit/UiMultiSelect.vue';

const props = defineProps({
  isTent: {
    type: Boolean,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update:is-tent']);

const tentTypesList = reactive([
  {
    name: 'Все',
    value: null,
  },
  {
    name: 'Тент',
    value: true,
  },
  {
    name: 'Реф',
    value: false,
  },
]);

const isValueUpdated = ref(null);

const tentType = computed(() => {
  if (typeof props.isTent === 'boolean') {
    return tentTypesList?.find((item) => item.value === props.isTent);
  }
  return isValueUpdated.value ? tentTypesList[0] : null;
});

const onUpdate = (value) => {
  emit('update:is-tent', value);

  if (!isValueUpdated.value) {
    isValueUpdated.value = true;
  }
};
</script>
