<template>
  <div>
    <banner
      :class="classes"
      :title="t('banners.feedback.title')"
      :text="t('banners.feedback.text')"
      :image="isDarkTheme ? feedbackImg : null"
      :background-position="isDarkTheme ? 'right center' : null"
      :has-button="true"
      :theme="theme"
    >
      <template #button>
        <ui-button
          :class="$style.button"
          color="secondary"
          size="large"
          @click="isFindOrderModalOpen = true"
        >
          {{ t('banners.feedback.submit') }}
        </ui-button>
      </template>
    </banner>

    <feedback-modal
      v-if="isFindOrderModalOpen"
      @close="isFindOrderModalOpen = false"
      @submit="submit"
    />

    <ui-modal-with-content
      v-if="isModalDialogVisible"
      :width="694"
      overlay-color="dark"
      @close="isModalDialogVisible = false"
    >
      <template #default>
        <div>
          <div :class="$style.dialog">
            <p>{{ t('submitModal.text1') }}</p>
            <p>{{ t('submitModal.text2') }}</p>

            <ui-button
              :class="$style.dialogButton"
              color="primary"
              size="large"
              @click="isModalDialogVisible = false"
            >
              {{ t('submitModal.submit') }}
            </ui-button>
          </div>
        </div>
      </template>
    </ui-modal-with-content>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  useCssModule,
  unref,
} from 'vue';
import { useI18n } from 'vue-i18n';

import Banner from '@/components/banners/Banner.vue';
import FeedbackModal from '@/components/banners/feedback/FeedbackModal.vue';
import UiButton from '@/kit/UiButton.vue';
import UiModalWithContent from '@/kit/UiModalWithContent.vue';
import feedbackImg from '@/assets/img/banner-feedback-dark-image.svg';

const style = useCssModule();

const props = defineProps({
  theme: {
    type: String,
    default: 'light',
    validate: (value) => ['dark', 'light'].includes(value),
  },
});

const { t } = useI18n();

const isFindOrderModalOpen = ref(false);
const theme = computed(() => props.theme);
const isDarkTheme = computed(() => props.theme === 'dark');

const classes = computed(() => ({
  [style.container]: true,
  [style.dark]: unref(isDarkTheme),
}));

const isModalDialogVisible = ref(false);
const submit = () => {
  isFindOrderModalOpen.value = false;
  isModalDialogVisible.value = true;
};
</script>

<style module>
.container {
  background-color: var(--color-white-100);
  background-image: url('~@/assets/img/banner-feedback.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}
.dark.container {
  background-image: none;
  background-color: var(--color-background-dark-theme);
}
.button {
  margin-top: 12px;
}

@media screen and (max-width: 767px) {
  .container {
    position: relative;
    background-color: var(--color-banner-blue-light);
    background-image: url('~@/assets/img/banner-feedback-mobile.png');
    background-repeat: no-repeat;
    background-position: center -50px;
  }

  .container .button {
    grid-area: button;
    max-width: 100px;
    margin-left: auto;
    height: 24px;
    font-size: var(--font-size-tiny);
    line-height: var(--line-height-small);
    padding-left: 6px;
    padding-right: 6px;
  }
}

.dialog {
  padding: 16px 70px 0;
}

@media screen and (max-width: 767px) {
  .dialog {
    padding: 0;
  }
}

.dialog p {
  font-size: var(--font-size-medium-header);
  line-height: var(--line-height-medium);
  color: var(--color-secondary-darker);
}
.dialog p:not(:last-child) {
  margin-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .dialog p {
    font-size: var(--font-size-base);
  }
}

.dialogButton {
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  .dialogButton {
    margin-top: 24px;
  }
}
</style>
