<template>
  <div :class="$style.wrapper">
    <div :class="$style.container">
      <svg-icon
        :class="$style.icon"
        name="direction"
      />
      <div :class="$style.iconMobile" />

      <div
        v-if="eventsCount"
        v-tooltip="tooltipOptions"
        :class="$style.badge"
      >
        +{{ eventsCount }}
      </div>

      <ui-skeleton
        v-if="isLoading"
        class="skeleton-absolute"
        width="100%"
        height="18px"
        :rounded="true"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import SvgIcon from '@/kit/SvgIcon.vue';
import UiSkeleton from '@/kit/UiSkeleton.vue';

const { t } = useI18n();

defineProps({
  orderId: {
    type: String,
    default: null,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  eventsCount: {
    type: Number,
    default: null,
  },
});

const tooltipOptions = computed(() => ({
  text: t('orders.loadingsPoints'),
  color: 'white',
  position: 'bottom',
  size: 'big',
}));
</script>

<style module>
.container {
  position: relative;
  width: 100px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .container {
    width: auto;
    min-width: 24px;
    height: 100%;
    align-items: center;
  }
}
.icon {
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 4px);
  width: 100px;
  height: 7.5px;
}
.iconMobile {
  display: none;
}
@media screen and (max-width: 767px) {
  .wrapper,
  .container {
    height: 100%;
  }
  .icon {
    display: none;
  }
  .iconMobile {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('~@/assets/img/direction_vertical.svg') no-repeat center;
    background-size: auto 100%;
  }
}
.badge {
  padding: 0px 5px;
  background: var(--color-primary);
  border: 1px solid var(--color-secondary);
  border-radius: var(--border-radius-base);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-xsmall);
  line-height: var(--line-height-medium);
  text-align: center;
  color: var(--color-white-100);
  cursor: pointer;
}

.wrapper {
  position: relative;
}
</style>
