<template>
  <div
    ref="loader"
    class="skeleton"
    :class="classes"
    :style="style"
  />
</template>

<script setup>
import {
  ref,
  unref,
  computed,
  onMounted,
} from 'vue';

const props = defineProps({
  size: {
    type: String,
    default: null,
  },
  height: {
    type: String,
    default: null,
  },
  width: {
    type: String,
    default: null,
  },
  animation: {
    type: String,
    default: 'shimmer',
  },
  type: {
    type: String,
    default: 'rect',
  },
  rounded: {
    type: Boolean,
    default: false,
  },
});

const loader = ref(null);

const loaderWidth = computed(() => props.size || props.width);
const loaderHeight = computed(() => props.size || props.height);

const style = computed(() => ({
  width: unref(loaderWidth),
  height: unref(loaderHeight),
}));

const classes = computed(() => `animation--${props.animation} shape--${
  props.type
} shape--${props.rounded ? 'round' : 'flat'}`);

onMounted(() => {
  const width = unref(loaderWidth);
  const height = unref(loaderHeight);

  unref(loader)?.style.setProperty('width', width);
  unref(loader)?.style.setProperty('height', height);
});
</script>

<style>
.skeleton {
  width: fit-content;
  cursor: wait;
  overflow: hidden;
  position: relative;
  --gradient-color: var(--color-light-gray);
  background-color: var(--color-light-gray);
}

.skeleton.skeleton-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Shape stylings */
.shape--text {
  height: 20px;
}
.shape--round {
  border-radius: var(--border-radius-base);
}

/* Animation definitions */
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse-x {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0.75);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes pulse-y {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.75);
  }
  100% {
    transform: scaleY(1);
  }
}
@keyframes wave {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Animation classes */
.animation--fade {
  animation: fade 1.5s linear .5s infinite;
}
.animation--wave::before {
  background: linear-gradient(
    90deg,
    transparent,
    var(--gradient-color),
    transparent
  );
  animation: wave 1.5s linear .5s infinite;
}
.animation--pulse-x {
  animation: pulse-x 1.5s linear .5s infinite;
}
.animation--pulse-y {
  animation: pulse-y 1.5s linear .5s infinite;
}
.animation--pulse {
  animation: pulse 1.5s linear .5s infinite;
}
.animation--shimmer:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, .3),
    rgba(37, 22, 22, 0)
  );
  animation: shimmer 1.5s infinite;
  content: '';
}
</style>
