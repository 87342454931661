<template>
  <div
    v-tooltip="tooltipOptions"
    :class="$style.container"
  >
    <ui-button
      appearance="clean"
    >
      {{ t('book') }}
    </ui-button>

    <ui-skeleton
      v-if="isLoading"
      class="skeleton-absolute"
      width="100%"
      height="100%"
      :rounded="true"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import UiButton from '@/kit/UiButton.vue';
import UiSkeleton from '@/kit/UiSkeleton.vue';

const { t } = useI18n();

defineProps({
  orderId: {
    type: [Number, String],
    default: null,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const tooltipOptions = computed(() => ({
  title: t('tooltip.title'),
  text: t('tooltip.text'),
  color: 'white',
}));
</script>

<style module>
.container {
  position: relative;
  text-align: right;
}
</style>
