<template>
  <banner
    :class="classes"
    :title="t('banners.finance.title')"
    :text="t('banners.finance.text')"
    :text-second="t('banners.finance.text2')"
    :text-list="textList"
    :image="isDarkTheme ? financeImg : null"
    :background-position="isDarkTheme ? 'right 20px' : null"
    :theme="theme"
  />
</template>

<script setup>
import {
  ref,
  computed,
  useCssModule,
  unref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Banner from '@/components/banners/Banner.vue';
import financeImg from '@/assets/img/banner-finance-dark-image.svg';

const style = useCssModule();

const props = defineProps({
  theme: {
    type: String,
    default: 'light',
    validate: (value) => ['dark', 'light'].includes(value),
  },
});

const { t } = useI18n();

const textList = ref([
  t('banners.finance.textMobile'),
  t('banners.finance.text2Mobile'),
]);

const theme = computed(() => props.theme);
const isDarkTheme = computed(() => props.theme === 'dark');

const classes = computed(() => ({
  [style.container]: true,
  [style.dark]: unref(isDarkTheme),
}));
</script>

<style module>
.container {
  background-image: url('~@/assets/img/banner-finance.png'),
    var(--banner-finance-shadow);
  background-repeat: no-repeat;
  background-position: calc(50% + 22px) center, center;
  background-size: auto, cover;
}
.dark.container {
  background-image: none;
  background-color: var(--color-background-dark-theme);
}
@media screen and (max-width: 767px) {
  .container {
    position: relative;
    background-color: var(--color-banner-purple);
    background-image: url('~@/assets/img/banner-finance-mobile.png');
    background-repeat: no-repeat;
    background-position: 43% -48px;
  }
}
</style>
