import dayjs from 'dayjs';

export const formatDate = (date, format = 'D MMMM') => {
  if (!date) {
    return '';
  }

  return dayjs(date)?.utc()?.format(format);
};

export const formatDateTime = (
  date,
  format = 'D MMM, H:mm',
) => {
  if (!date) {
    return '';
  }
  return dayjs(date)?.format(format);
};

export const sortArrayByDate = (arr, dateField = 'startDateTime') => {
  if (!arr || !arr?.length) {
    return arr;
  }
  return arr.sort((a, b) => (
    dayjs(a?.[dateField]).isAfter(dayjs(b?.[dateField])) ? 1 : -1
  ));
};

export const diffMinutesFromDate = (date) => dayjs(date).diff(dayjs(), 'minute');
