<template>
  <div class="content-area">
    <div :class="$style.container">
      <card
        :title="t('cards.first.title')"
        :text="t('cards.first.text')"
        :img="firstCardImg"
        :theme="theme"
      />
      <card
        :title="t('cards.second.title')"
        :text="t('cards.second.text')"
        :img="secondCardImg"
        :theme="theme"
      />
      <card
        :title="t('cards.third.title')"
        :text="t('cards.third.text', { url })"
        :img="thirdCardImg"
        :fill-image="true"
        :theme="theme"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import config from '@/services/config';
import Card from '@/components/cards/Card.vue';
import firstCardImg from '@/assets/img/card-first.png';
import secondCardImg from '@/assets/img/card-second.png';
import thirdCardImg from '@/assets/img/card-third.png';

const { t } = useI18n();

const store = useStore();

const metrikaClientId = computed(() => store.state.metrikaClientId);

const href = computed(() => config.host);
const regKeycloakURI = computed(() => `auth/realms/globaltruck/protocol/openid-connect/registrations
?client_id=gt-arm
&redirect_uri=https%3A%2F%2Fportal.globaltruck.ru%2F%3F
source%3Dabout.globaltruck.ru%26regId%3D${metrikaClientId.value}
&response_mode=fragment&response_type=code&scope=openid`);
const url = `${href.value}${regKeycloakURI.value}`;

const theme = computed(() => config.theme);
</script>

<style module>
.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 17px;
  margin-bottom: 32px;
}

@media screen and (max-width: 767px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: 16px;
  }
}
</style>
