<template>
  <ui-multi-select
    :model-value="place"
    :class="{ [$style.hasPlaces]: places && places.length }"
    :placeholder="placeholder || ''"
    :options="places"
    :show-arrow-icon="false"
    label="name"
    track-by="name"
    :custom-label="customLabel"
    @search-change="onSearch"
    @close="onClose"
  />
</template>

<script setup>
import {
  ref,
  unref,
} from 'vue';
import debounce from 'lodash/debounce';
import UiMultiSelect from '@/kit/UiMultiSelect.vue';
import useFiltersLists from '@/composable/orders/useFiltersLists';

const props = defineProps({
  place: {
    type: Object,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  regionsList: {
    type: Array,
    default: null,
  },
});

defineEmits(['update']);

const cities = ref([]);
const regions = ref([]);

const {
  getCities,
  places,
} = useFiltersLists(
  cities,
  regions,
);

const onSearch = debounce((name) => {
  if (name && name.length < 3) {
    return;
  }
  search(name);
}, 300);

const search = async (name) => {
  regions.value = unref(props.regionsList)
    ?.filter((item) => item.name.toLowerCase().indexOf(name?.toLowerCase()) > -1) || [];

  cities.value = await getCities({ name });
};

const onClose = () => {
  cities.value = [];
  regions.value = [];
};

const customLabel = (place) => {
  if (!place?.regionId) {
    return place.name;
  }
  const currentRegion = unref(props.regionsList)?.find((item) => item.id === place.regionId);

  if (currentRegion) {
    return `${place.name}, ${currentRegion.name}`;
  }

  return place.name;
};
</script>

<style module>
  .hasPlaces:global.multiselect--active {
    z-index: 52;
  }
</style>
