import { createStore } from 'vuex';

export default createStore({
  state: {
    ordersCount: null,
    metrikaUser: null,
    metrikaClientId: '',
    managersList: [],
  },
  mutations: {
    setMetrikaUser(state, user) {
      state.metrikaUser = user;
    },

    setMetrikaClientId(state, clientId) {
      state.metrikaClientId = clientId;
    },

    setManagers(state, managers) {
      state.managersList = managers;
    },

    setOrdersCount(state, count) {
      state.ordersCount = count;
    },
  },
  actions: {
    ordersCount({ commit }, count) {
      commit('setOrdersCount', count);
    },
  },
  modules: {
  },
});
