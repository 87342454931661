const useWindowScroll = () => {
  const content = global;

  const scrollContentTop = () => {
    if (content) {
      content.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return {
    scrollContentTop,
  };
};

export default useWindowScroll;
