<template>
  <banner
    :class="classes"
    :title="isDarkTheme ? t('banners.online.titleV2') : t('banners.online.title')"
    :text="t('banners.online.text')"
    :text-second="t('banners.online.text2')"
    :text-list="textList"
    :is-small-title="true"
    :image="isDarkTheme ? onlineImg : null"
    :background-position="isDarkTheme ? 'right 20px' : null"
    :theme="theme"
  />
</template>

<script setup>
import {
  ref,
  computed,
  useCssModule,
  unref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Banner from '@/components/banners/Banner.vue';
import onlineImg from '@/assets/img/banner-online-dark-image.svg';

const style = useCssModule();

const props = defineProps({
  theme: {
    type: String,
    default: 'light',
    validate: (value) => ['dark', 'light'].includes(value),
  },
});

const { t } = useI18n();

const textList = ref([
  t('banners.online.textMobile'),
  t('banners.online.text2Mobile'),
]);

const theme = computed(() => props.theme);
const isDarkTheme = computed(() => props.theme === 'dark');

const classes = computed(() => ({
  [style.container]: true,
  [style.dark]: unref(isDarkTheme),
}));
</script>

<style module>
.container {
  background-image: url('~@/assets/img/banner-online.png'),
    var(--banner-online-shadow);
  background-repeat: no-repeat;
  background-position: calc(50% - 26px) center, center;
  background-size: auto, cover;
}

.dark.container {
  background-image: none;
  background-color: var(--color-background-dark-theme);
}

@media screen and (max-width: 767px) {
  .container {
    position: relative;
    background-color: var(--color-banner-purple-light);
    background-image: url('~@/assets/img/banner-online-mobile.png');
    background-repeat: no-repeat;
    background-position: 43% -52px;
  }
}
</style>
