<template>
  <div :class="{ [$style.dark]: isDarkTheme }">
    <filters-group
      :is-tent="filters.isTent"
      :loading-place="filters.loadingPlace"
      :unloading-place="filters.unloadingPlace"
      :load-date-from="filters.loadDateFrom"
      :load-date-to="filters.loadDateTo"
      :class="$style.filters"
      :is-loading="loading"
      @update:load-date-from="updateLoadDateFrom"
      @update:load-date-to="updateLoadDateTo"
      @update:loading-place="updateLoadingPlace"
      @update:unloading-place="updateUnloadingPlace"
      @update:is-tent="updateIsTent"
      @submit="onSubmit"
    />

    <div
      v-if="emptyOrders"
      class="content-area"
    >
      <orders-form
        :theme="theme"
        @close="clearFilters"
      />
    </div>

    <template v-else>
      <div class="content-area">
        <orders-table
          :headers="filteredHeaders"
          :orders="orders"
          :total-pages="totalPages"
          :size="size"
          :page="page"
          :is-loading="loading"
          @update:page="onChangePage"
          @update:size="onChangeSize"
          @load-more="onLoadMore"
          @click:order="selectOrder"
        />
      </div>
    </template>

    <order
      v-if="!!selectedOrder"
      :order-id="selectedOrder"
      @close="onCloseOrder"
    />
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  unref,
  computed,
  onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { isDate } from 'lodash';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import {
  useRoute,
  useRouter,
} from 'vue-router';
import { useToast } from 'vue-toastification';
import {
  decodeQueryParams,
  encodeQueryParams,
  NumberParam,
  BooleanParam,
  DateParam,
  StringParam,
  withDefault,
} from 'serialize-query-params';
import { useI18n } from 'vue-i18n';
import config from '@/services/config';

import OrderService from '@/services/OrderService';
import FiltersGroup from '@/components/filters/FiltersGroup.vue';
import OrdersTable from '@/components/OrdersTable.vue';
import OrdersForm from '@/components/Form.vue';
import Order from '@/components/Order.vue';

import { getHTTPError } from '@/utils/errors';
import stringToConstantCase from '@/filters/string';
import { getDateForCurrentTimeZone } from '@/utils/handlers';

import useFiltersLists from '@/composable/orders/useFiltersLists';
import useWindowScroll from '@/composable/window';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const toast = useToast();
const store = useStore();

const theme = computed(() => config.theme);
const isDarkTheme = computed(() => config.theme === config.themes.DARK);

const page = ref(1);
const pageSchema = ref({
  page: NumberParam,
});

const orderSchema = ref({
  orderId: StringParam,
});

const {
  getCities,
  getRegions,
} = useFiltersLists();

const headers = ref([
  {
    title: t('orders.order'),
    key: 'order',
    show: true,
    isSingleLine: true,
  },
  {
    title: t('orders.route'),
    key: 'route',
    show: true,
    isSingleLine: true,
  },
  {
    key: 'direction',
    show: true,
    isSingleLine: true,
  },
  {
    title: t('orders.routeEnd'),
    key: 'routeEnd',
    show: true,
    isSingleLine: true,
  },
  {
    title: t('orders.trailer'),
    key: 'trailer',
    show: true,
    isSingleLine: true,
  },
  {
    key: 'price',
    show: true,
    isSingleLine: true,
  },
  {
    key: 'book',
    show: true,
    isSingleLine: true,
  },
]);
const filteredHeaders = computed(() => unref(headers)?.filter((item) => item.show));

const filters = reactive({
  isTent: null,
  loadingPlace: null,
  unloadingPlace: null,
  loadDateFrom: null,
  loadDateTo: null,
});

/**
 * Filters
*/
const filtersSchema = ref({
  isTent: withDefault(
    BooleanParam,
    null,
    false,
  ),
  loadDateTo: DateParam,
  loadDateFrom: DateParam,
  firstCityName: StringParam,
  lastCityName: StringParam,
  firstRegionName: StringParam,
  lastRegionName: StringParam,
});

const filtersQuery = computed(() => {
  const computedFilters = {};

  Object.entries(unref(filters)).forEach(([fieldName, fieldValue]) => {
    const filterKey = fieldName;
    const value = fieldValue;

    if (filterKey === 'loadingPlace') {
      if (value?.regionId) {
        Object.assign(computedFilters, {
          firstCityName: value?.name?.toString(),
        });
      } else {
        Object.assign(computedFilters, {
          firstRegionName: value?.name?.toString(),
        });
      }
      return;
    }

    if (filterKey === 'unloadingPlace') {
      if (value?.regionId) {
        Object.assign(computedFilters, {
          lastCityName: value?.name?.toString(),
        });
      } else {
        Object.assign(computedFilters, {
          lastRegionName: value?.name?.toString(),
        });
      }
      return;
    }

    if (value !== 'undefined') {
      Object.assign(computedFilters, { [filterKey]: value });
    }
  });

  return computedFilters;
});

const localFilters = computed(() => {
  const computedFilters = {};

  Object.entries(unref(filtersQuery)).forEach(([fieldName, fieldValue]) => {
    const filterKey = fieldName;
    let value = fieldValue;

    if (filterKey === 'loadDateFrom' && isDate(value)) {
      value = dayjs(getDateForCurrentTimeZone(value)).format('YYYY-MM-DDT00:00:00');
    }

    if (filterKey === 'loadDateTo' && isDate(value)) {
      value = dayjs(getDateForCurrentTimeZone(value))
        .utc()
        .endOf('day')
        .format()
        .substring(0, 19);
    }

    if (value !== 'undefined') {
      Object.assign(computedFilters, { [stringToConstantCase(filterKey)]: value?.toString() });
    }
  });

  return computedFilters;
});

/**
 * Orders
*/
const loading = ref(false);
const size = ref(12);
const orderService = new OrderService();
const orders = ref(null);
const totalPages = ref(1);
const savePrevPageItems = ref(false);

const dataForFilters = computed(() => {
  const ordersFilterDTO = {
    filters: unref(localFilters),
  };
  const params = {
    page: unref(page) > 0 ? unref(page) - 1 : 0,
    size: unref(size),
  };
  return {
    ordersFilterDTO,
    params,
  };
});

const emptyOrders = computed(() => orders.value && orders.value.length === 0);

const loadOrders = async () => {
  if (!unref(dataForFilters)) {
    return;
  }
  loading.value = true;

  const ordersResponse = await orderService.getOrders(
    unref(dataForFilters).ordersFilterDTO,
    unref(dataForFilters).params,
  );

  loading.value = false;

  if (unref(savePrevPageItems)) {
    orders.value = [...unref(orders), ...ordersResponse?.content];
  } else {
    orders.value = ordersResponse?.content;
  }
  savePrevPageItems.value = false;

  totalPages.value = ordersResponse?.totalPages;
  store.dispatch('ordersCount', ordersResponse?.totalElements);
};

const loadAndSync = debounce(() => {
  const queryParams = {};

  Object.entries(route.query).forEach(([fieldName, fieldValue]) => {
    const filterKey = fieldName;
    const value = fieldValue;

    if ([
      'firstCityName',
      'firstRegionName',
      'lastCityName',
      'lastRegionName',
    ].includes(filterKey)
    ) {
      if (filtersQuery?.value[filterKey] === undefined) {
        return;
      }
    }

    queryParams[filterKey] = value;
  });

  router.push({
    query: {
      ...queryParams,
      ...encodeQueryParams(
        unref(filtersSchema),
        unref(filtersQuery),
      ),
      ...encodeQueryParams(
        unref(pageSchema),
        {
          page: unref(page),
        },
      ),
    },
  });

  loadOrders();
}, 300);

const { scrollContentTop } = useWindowScroll();
const onChangePage = (newPage) => {
  page.value = newPage;

  loadAndSync();
  scrollContentTop();
};

const onChangeSize = (newSize) => {
  page.value = 1;
  size.value = newSize;

  loadAndSync();
};

const onLoadMore = (newPage) => {
  page.value = newPage;
  savePrevPageItems.value = true;

  loadAndSync();
};

const onSubmit = () => {
  page.value = 1;
  loadAndSync();
};

const updateLoadingPlace = (val) => {
  filters.loadingPlace = val;
};
const updateUnloadingPlace = (val) => {
  filters.unloadingPlace = val;
};
const updateIsTent = (val) => {
  filters.isTent = val?.value;
};
const updateLoadDateFrom = (val) => {
  filters.loadDateFrom = val;
};
const updateLoadDateTo = (val) => {
  filters.loadDateTo = val;
};

const setInternalFilters = async (data) => {
  filters.isTent = data?.isTent;
  filters.loadDateFrom = data?.loadDateFrom;
  filters.loadDateTo = data?.loadDateTo;

  if (data.firstCityName) {
    const firstCityName = await getCities({ name: data.firstCityName });
    filters.loadingPlace = firstCityName?.[0] || null;
  }
  if (data.firstRegionName) {
    const firstRegionName = await getRegions({ name: data.firstRegionName });
    filters.loadingPlace = firstRegionName?.[0] || null;
  }
  if (data.lastCityName) {
    const lastCityName = await getCities({ name: data.lastCityName });
    filters.unloadingPlace = lastCityName?.[0] || null;
  }
  if (data.lastRegionName) {
    const lastRegionName = await getRegions({ name: data.lastRegionName });
    filters.unloadingPlace = lastRegionName?.[0] || null;
  }
};

const clearFilters = () => {
  filters.isTent = null;
  filters.loadingPlace = null;
  filters.unloadingPlace = null;
  filters.loadDateFrom = null;
  filters.loadDateTo = null;
  onSubmit();
};

const selectedOrder = ref(null);

const selectOrder = (index) => {
  selectedOrder.value = orders.value?.[index].orderId || null;

  if (selectedOrder.value) {
    const orderId = selectedOrder.value;

    router.push({
      query: {
        ...route.query,
        orderId,
      },
    });
  }
};

const onCloseOrder = () => {
  selectedOrder.value = null;

  router.push({
    query: {
      ...route.query,
      orderId: undefined,
    },
  });
};

onMounted(async () => {
  const {
    page: decodedPage,
    orderId: decodedOrderId,
    ...decodedFilters
  } = decodeQueryParams(
    {
      ...unref(filtersSchema),
      ...unref(pageSchema),
      ...unref(orderSchema),
    },
    route.query,
  );

  if (decodedPage) {
    page.value = decodedPage;
  }

  if (decodedOrderId) {
    const orderId = decodedOrderId.split('?')[0];
    selectedOrder.value = orderId;
  }

  try {
    await setInternalFilters(decodedFilters);
    await loadOrders();
  } catch (e) {
    toast.error(getHTTPError(e));
  }
});
</script>

<style module>
.filters {
  position: relative;
  margin-top: -50px;
}

@media screen and (max-width: 767px) {
  .filters {
    margin-top: 0;
  }
}
</style>
