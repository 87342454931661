<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <ui-carousel
        :pagination="false"
        :slides-per-view="4.2"
        :space-between="0"
        :watch-slides-progress="true"
        :loop="false"
      >
        <swiper-slide
          v-for="item in days"
          :key="item.id"
          :class="$style.slide"
          @click="onClick(item)"
        >
          <div :class="$style.item">
            {{ formatDate(item.value, 'ddd D MMM') }}
          </div>
        </swiper-slide>
      </ui-carousel>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  onMounted,
} from 'vue';
import { SwiperSlide } from 'swiper/vue';
import dayjs from 'dayjs';
import { formatDate } from '@/filters/date';
import UiCarousel from '@/kit/UiCarousel.vue';

const emit = defineEmits(['update']);

window.dayjs = dayjs;
const days = reactive([]);
const daysTotal = ref(13);

onMounted(() => {
  for (let i = 0; i <= daysTotal.value; i += 1) {
    days.push({
      id: i,
      value: dayjs().add(i, 'day'),
    });
  }
});

const onClick = (item) => {
  if (!item?.value?.toDate) {
    return;
  }

  emit('update', item?.value?.toDate());
};
</script>

<style module>
.container {
  position: relative;
  width: calc(100% + 32px);
  margin: 0 -16px 16px;
}

.content {
  position: relative;
}

.item {
  border-top: 1px solid var(--color-secondary-light);
  border-bottom: 1px solid var(--color-secondary-light);
  position: relative;
  cursor: pointer;
  padding: 16px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-secondary);
  white-space: nowrap;
}
.slide:first-child .item {
  border-left: 1px solid var(--color-secondary-light);
}
.slide:not(:last-child) .item {
  border-right: 1px solid var(--color-secondary-light);
}

.item:hover,
.slide:first-child .item:hover,
.slide:not(:last-child) .item:hover {
  border-color: var(--color-primary);
}
.item:hover::after {
  content: '';
  position: absolute;
  left: -1px;
  top: 0;
  width: 1px;
  height: 100%;
  background: var(--color-primary);
  z-index: 1;
}
</style>
