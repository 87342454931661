import { h } from 'vue';
import { createI18n } from 'vue-i18n';
import Toast, { POSITION } from 'vue-toastification';
import VueYandexMetrika from 'vue3-yandex-metrika';
import router from '@/router';
import config from '@/services/config';
import SvgIcon from '@/kit/SvgIcon.vue';

import maskDirective from '@/directives/mask';
import clickOutside from '@/directives/click-outside';
import tooltip from '@/directives/tooltip';
import ru from '@/locales/ru.json';
import en from '@/locales/en.json';

const queryParams = new URLSearchParams(window.location.search);
const theme = queryParams.get('theme');

if (theme) {
  config.theme = theme;
}

const toastConfig = {
  position: POSITION.BOTTOM_CENTER,
  timeout: 5000,
  maxToasts: 5,
  hideProgressBar: true,
  toastClassName: 'gt-toast',
  closeButton: () => (
    h(
      'button',
      { class: 'Vue-Toastification__close-button' },
      [
        h(
          SvgIcon,
          { name: 'close' },
        ),
      ],
    )
  ),
  filterBeforeCreate(toast, toasts) {
    const hasSameToasts = toasts.some((item) => item.content === toast.content);
    if (hasSameToasts) {
      return false;
    }
    return toast;
  },
};

const messages = {
  ru,
  en,
};

const i18n = createI18n({
  legacy: false,
  locale: 'ru',
  fallbackLocale: 'ru',
  messages,
});

export default function registerVuePlugins(app) {
  if (config.yandexMetrikaId) {
    app.use(VueYandexMetrika, {
      id: config.theme === config.themes.DARK
        ? config.yandexMetrikaIdV2 : config.yandexMetrikaId,
      router,
      env: process.env.NODE_ENV,
      options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        triggerEvent: true,
      },
    });
  }

  return app
    .directive('mask', maskDirective)
    .directive('click-outside', clickOutside)
    .directive('tooltip', tooltip)
    .use(i18n)
    .use(Toast, toastConfig);
}
