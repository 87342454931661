export const API_GET_ORDER_LIST = '/public/order/showcase';
export const API_ORDER_LOADING_EVENTS = '/public/order/showcase/loadingEvents';
export const API_GET_REGIONS = '/region';
export const API_GET_CITIES = '/city';
export const API_SEND_FEEDBACK = '/mail/send/forFeedback/orders-not-found';
export const API_GET_ORDER_ADDITIONAL_INFO = (
  orderId,
) => `/public/order/showcase/additionalInfo/${orderId}`;

export const API_GET_ORDER = (
  orderId,
) => `/public/order/showcase/${orderId}`;
export const API_DOWNLOAD_FUNC_CHARACTERISTICS = '/file/download/func-characteristics-gt2';
export const API_DOWNLOAD_PRIVACY_POLICY = '/file/download/privacy-policy';
export const API_DOWNLOAD_TECH_SUPPORT = '/file/download/tech-support-gt2';
