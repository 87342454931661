import {
  computed,
} from 'vue';
import { useToast } from 'vue-toastification';
import { getHTTPError } from '@/utils/errors';
import OrderService from '@/services/OrderService';

const useFiltersLists = (cities, regions) => {
  const countryId = '795d6d93-a55e-4344-a257-c5769e15d083';
  const orderService = new OrderService();
  const toast = useToast();

  const getRegions = async () => {
    const requestParams = {
      countryId,
    };

    try {
      const regionsResponse = await orderService.getRegions(requestParams);
      return regionsResponse;
    } catch (e) {
      toast.error(getHTTPError(e));
      return null;
    }
  };

  const getCities = async (params) => {
    const requestParams = {
      ...params,
      countryId,
    };
    try {
      const citiesResponse = await orderService.getCities(requestParams);
      return citiesResponse?.content;
    } catch (e) {
      toast.error(getHTTPError(e));
      return null;
    }
  };

  const places = computed(() => ([
    ...cities.value,
    ...regions.value,
  ]));

  return {
    places,
    getRegions,
    getCities,
  };
};

export default useFiltersLists;
