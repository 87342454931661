<template>
  <div
    :class="classes"
    @click="onClick"
  >
    <div
      v-if="isHavePrependedContent"
      :class="$style.prepend"
    >
      <slot name="prepend">
        <svg-icon
          :class="$style.leftIcon"
          :name="leftIcon"
        />
      </slot>
    </div>
    <div :class="$style.control">
      <input
        :id="internalId"
        ref="input"
        v-mask="mask"
        data-testid="UiInputText"
        :class="$style.input"
        :value="inputValue"
        :disabled="disabled"
        v-bind="$attrs"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
        @accept="onAccept"
        @complete="onComplete"
      >
    </div>
    <div
      v-if="isHaveAppendedContent"
      :class="$style.append"
    >
      <slot name="append">
        <svg-icon
          v-if="rightIcon"
          :class="rightIconClasses"
          :name="rightIcon"
          @click="$emit('clean')"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/kit/SvgIcon.vue';
import inputMixin from '@/kit/mixins/input';

export default {
  name: 'UiInputText',

  components: {
    SvgIcon,
  },

  mixins: [
    inputMixin,
  ],

  emits: [
    'update:modelValue',
    'clean',
  ],

  data() {
    return {
      isInputEvent: false,
      maskedValue: this.modelValue,
    };
  },

  computed: {
    inputValue() {
      return this.mask ? this.maskedValue : this.modelValue;
    },

    internalId() {
      // eslint-disable-next-line no-underscore-dangle
      return this.id;
    },

    rightIconClasses() {
      return {
        [this.$style.rightIcon]: true,
        [this.$style.hover]: this.rightIcon === 'close',
      };
    },
  },

  watch: {
    value: {
      handler(value) {
        if (!this.isInputEvent) {
          this.maskedValue = value;
        }
        this.isInputEvent = false;
      },
    },
  },

  methods: {
    onInput(e) {
      if (!this.mask) {
        this.$emit('update:modelValue', e.target.value);
      } else {
        this.isInputEvent = true;
      }
    },

    onAccept(e) {
      this.maskedValue = e.detail.value;
      this.$emit('update:modelValue', e.detail.unmaskedValue);
    },

    onComplete(e) {
      this.$emit('update:modelValue', e.detail.unmaskedValue);
    },
  },
};
</script>

<style module>
.container {
  display: flex;
  flex-flow: row;
  cursor: text;

  background-clip: padding-box;
  border-radius: var(--border-radius-base);
  box-shadow: inset 0 0 0 1px var(--color-secondary-light);
  background: var(--color-white-100);

  height: var(--size-form-element-height-large);
  padding: 2px 8px 2px 16px;

  transition: box-shadow var(--timing-input-animation) ease-in-out;
}

.container.isDisabled {
  opacity: 0.33;
}

.container.isInvalid {
  box-shadow: inset 0 0 0 1px var(--color-danger);
}

.container:not(.isDisabled):hover {
  box-shadow: inset 0 0 0 1px var(--color-secondary-light);
  background: var(--color-secondary-lightest);
}

.container:not(.isDisabled).isFocused {
  background: var(--color-white-100);
}

.container:not(.isDisabled).isFocused {
  box-shadow: inset 0 0 0 1px var(--color-primary);
}

.container:not(.isDisabled).isInvalid:hover {
  box-shadow: inset 0 0 0 1px var(--color-danger);
}

.container:not(.isDisabled).isInvalid .isFocused {
  box-shadow: inset 0 0 0 1px var(--color-danger);
}

.container.clean {
  box-shadow: none;
  border-bottom: 1px solid var(--color-secondary-light);
  border-radius: 0;
  padding: 0;
}

.container.clean:hover {
  box-shadow: none;
  border-color: var(--color-primary);
  background: var(--color-white-100);
}
.container.clean.isFocused {
  box-shadow: none;
  border-color: var(--color-primary);
}

.control {
  display: flex;
  flex: 1 1 auto;
}

.input {
  font: inherit;
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  max-width: 100%;

  padding: 8px 0;
  border-radius: var(--border-radius-base);

  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  color: var(--color-secondary-darker);
}

@media screen and (max-width: 767px) {
  .input {
    font-size: var(--font-size-base);
  }
}

.input::placeholder {
  color: var(--color-secondary);
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--color-white-100) inset;
}

.prepend {
  margin-right: 10px;
  margin-left: -2px;
}

.append {
  margin-left: 10px;
  margin-right: -2px;
}

.prepend,
.append {
  display: inline-flex;
}

.leftIcon,
.rightIcon {
  width: 20px;
  height: 20px;
  align-self: center;
  color: var(--color-secondary);
}
.hover{
  cursor: pointer;
}
</style>
