<template>
  <div :class="classes">
    <div
      v-if="!isLoading"
      @click="isLoadingEventsVisible = true"
    >
      <div :class="$style.title">
        {{ type }} 20 т
      </div>
      <div
        v-if="isDescriptionVisible"
        :class="$style.description"
      >
        t° {{ temperatureFrom }}..{{ temperatureTo }}
      </div>
    </div>

    <template v-else>
      <ui-skeleton
        v-if="isLoading"
        :class="$style.skeleton"
        width="27px"
        height="18px"
        :rounded="true"
      />
      <ui-skeleton
        v-if="isLoading"
        :class="$style.skeleton"
        width="52px"
        height="18px"
        :rounded="true"
      />
    </template>

    <cell-info
      v-if="isLoadingEventsVisible && showInfo"
      @close="isLoadingEventsVisible = false"
    >
      <div>
        {{ t('table.hint') }}
      </div>
    </cell-info>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  useCssModule,
  unref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import UiSkeleton from '@/kit/UiSkeleton.vue';
import CellInfo from '@/components/cells/CellInfo.vue';

const style = useCssModule();
const { t } = useI18n();

const props = defineProps({
  isTent: {
    type: Boolean,
    default: false,
  },
  temperatureFrom: {
    type: [Number, String],
    default: null,
  },
  temperatureTo: {
    type: [Number, String],
    default: null,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  showInfo: {
    type: Boolean,
    default: false,
  },
  isCard: {
    type: Boolean,
    default: false,
  },
});

const type = computed(() => (props.isTent
  ? t('trailerTypes.tent')
  : t('trailerTypes.ref')));

const isDescriptionVisible = computed(() => (
  !props.isTent && (props.temperatureFrom || props.temperatureTo)
));

const isLoadingEventsVisible = ref(true);

const classes = computed(() => ({
  [style.container]: true,
  [style.card]: unref(props.isCard),
}));
</script>

<style module>
.container {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height-medium);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 56px;
  justify-content: center;
}
.card.container {
  justify-content: flex-start;
  min-height: auto;
}
.title {
  position: relative;
  color: var(--color-secondary-darker);
  white-space: nowrap;
}
.description {
  position: relative;
  margin-top: 4px;
  color: var(--color-secondary);
}

.card .description {
  font-size: var(--font-size-xsmall);
  line-height: var(--line-height-medium);
}

.skeleton:not(:last-child) {
  margin-bottom: 4px;
}
</style>
