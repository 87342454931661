<script>
import { h, useCssModule } from 'vue';

const SvgIcon = (props, context) => {
  const style = useCssModule();

  // eslint-disable-next-line
  const { url: icon } = require(`@/assets/icons/${props.name}.svg`).default;

  const use = h('use', {
    href: icon,
    'xlink:href': icon,
  });

  const attrs = {
    ...context.attrs,
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: props.viewBox,
    'aria-hidden': true,
  };

  attrs.class = `${style.svg}`;

  return h('svg', attrs, [use]);
};

SvgIcon.props = {
  name: {
    type: String,
    required: true,
  },
  viewBox: {
    type: String,
    default: null,
  },
};

export default SvgIcon;
</script>

<style module>
.svg {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  width: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  fill: currentColor;
}
</style>
