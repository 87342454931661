<template>
  <div>
    <div :class="classes">
      <app-header />

      <main :class="$style.main">
        <router-view
          id="content"
          :class="$style.content"
        />
      </main>

      <app-footer />
    </div>

    <div id="modal" />

    <div :class="$style.fixedBlock">
      <phone :class="$style.button" />
      <telegram-button :class="$style.button" />
      <scroll-top :class="$style.scroll" />
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  onMounted,
  useCssModule,
  unref,
} from 'vue';

import config from '@/services/config';
import AppHeader from '@/layout/AppHeader.vue';
import AppFooter from '@/layout/AppFooter.vue';
import ScrollTop from '@/kit/ScrollTop.vue';
import Phone from '@/components/Phone.vue';
import TelegramButton from '@/components/TelegramButton.vue';
import useMetrika from '@/composable/useMetrika';

const style = useCssModule();
const {
  initMetrika,
} = useMetrika();

const isDarkTheme = computed(() => config.theme === config.themes.DARK);

const classes = computed(() => ({
  [style.container]: true,
  [style.dark]: unref(isDarkTheme),
}));

onMounted(() => {
  initMetrika();
});
</script>

<style module>
.container {
  display: flex;
  flex-flow: column;
  background: var(--color-light-gray);
}
.dark.container {
  background: var(--color-white-100);
}
.main {
  display: flex;
  flex-direction: column;
}
.content {
  height: 100%;
  flex: 1 0;
}
.fixedBlock {
  position: fixed;
  right: -16px;
  bottom: 32px;
  z-index: var(--z-index-fixed-block);
}
.scroll {
  margin-top: 8px;
}
.button {
  left: -16px;
}
</style>
