<template>
  <div
    :class="{
      [$style.isInvalid]: isInvalid,
    }"
  >
    <ui-label
      v-if="computedLabel"
      :class="$style.label"
      :for="computedId"
      :prepend-icon="prependIcon"
    >
      {{ computedLabel }}
    </ui-label>

    <slot
      :id="computedId"
      :is-invalid="isInvalid"
    />

    <div :class="$style.messageContainer">
      <div
        v-if="errorMessage"
        :class="$style.errorMessage"
      >
        {{ errorMessage }}
      </div>
      <div
        v-else-if="message"
        :class="$style.message"
      >
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import UiLabel from '@/kit/UiLabel.vue';

const errors = {
  required: () => 'Обязательное поле',
  minLength: ({ min }) => `Минимум ${min} символов`,
  maxLength: ({ max }) => `Максимум ${max} символов`,
  email: () => 'Введите корректный адрес почты',
  checkEndDate: () => 'Дата не может быть раньше начальной',
  checkStartDate: () => 'Дата должна быть раньше конечной',
  aboveZero: () => 'Значение должно быть больше 0',
  checkEndDateBetweenPeriod: () => 'Есть периоды с более поздней датой',
  checkStartDateBetweenPeriod: () => 'Есть периоды с более ранней датой',
  isActualDate: () => 'Дата не может быть прошедшей',
  vehicleCountValidateFractional: () => 'Количество не может быть дробным',
  notNull: () => 'Значение должно быть больше 0',
  endDateMoreThenStart: () => 'Дата должна быть позже начальной',
};

export default {
  name: 'UiFormGroup',

  components: {
    UiLabel,
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    field: {
      type: Object,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    customErrorMessage: {
      type: String,
      default: null,
    },
  },

  computed: {
    computedId() {
      return this.id || `input-${this?.$?.uid}`;
    },

    computedLabel() {
      if (!this.label) {
        return null;
      }

      return `${this.label}${this.isRequired ? '*' : ''}`;
    },

    isRequired() {
      return !!(this.field?.required);
    },

    isInvalid() {
      return this.field?.$error;
    },

    errorMessage() {
      if (!this.field || !this.isInvalid) {
        return '';
      }
      let message = '';
      this.field.$errors
        .forEach((item) => {
          const defaultError = this.customErrorMessage ?? 'Ошибка';
          const key = item?.$params?.type;
          message = Object.hasOwnProperty.call(errors, key)
            ? errors[key](item?.$params)
            : defaultError;
        });

      return message;
    },
  },
};
</script>

<style module>
.label {
  margin-bottom: 8px;
}

.messageContainer {
  min-height: 16px;
  margin-top: 2px;
}

.errorMessage {
  color: var(--color-red-100);
  font-size: var(--font-size-small);
  line-height: 14px;
  opacity: 0;
}

.isInvalid .errorMessage {
  opacity: 1;
}

.message {
  color: var(--color-black-67);
  font-size: var(--font-size-small);
  line-height: 14px;
  padding-left: 10px;
}
</style>
