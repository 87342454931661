import UiInputProps from '@/kit/UiInput.props';

export default {
  props: {
    ...UiInputProps,
  },

  emits: [
    'blur',
    'focus',
    'update:modelValue',
  ],

  data() {
    return {
      isFocused: false,
    };
  },

  computed: {
    classes() {
      const { appearance } = this;

      return {
        [this.$style.container]: true,
        [this.$style[appearance]]: true,
        [this.$style.isInvalid]: this.isInvalid,
        [this.$style.isDisabled]: this.disabled,
        [this.$style.isFocused]: this.isFocused,
      };
    },

    isDisabled() {
      return this.$attrs.disabled;
    },

    isHaveAppendedContent() {
      return this.$slots.append || this.rightIcon;
    },

    isHavePrependedContent() {
      return this.$slots.prepend || this.leftIcon;
    },
  },

  methods: {
    onClick() {
      this.$refs.input.focus();
    },

    onBlur(event) {
      this.isFocused = false;

      if (event) {
        this.$nextTick(() => {
          this.$emit('blur', event);
        });
      }
    },

    onFocus(event) {
      if (!this.$refs.input) {
        return;
      }

      if (document.activeElement !== this.$refs.input) {
        this.$refs.input.focus();
        return;
      }

      if (!this.isFocused) {
        this.isFocused = true;

        if (event) {
          this.$emit('focus', event);
        }
      }
    },

    onInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
