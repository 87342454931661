<template>
  <div>
    <banners />
    <orders />
    <cards />
  </div>
</template>

<script setup>
import Banners from '@/components/banners/Banners.vue';
import Orders from '@/components/Orders.vue';
import Cards from '@/components/cards/Cards.vue';
</script>
