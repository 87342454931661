<template>
  <swiper
    v-bind="$attrs"
    :slides-per-view="slidesPerView"
    :pagination="pagination"
    :navigation="navigation"
    :space-between="spaceBetween"
    :centered-slides="centeredSlides"
    :loop="loop"
    :class="{
      [$style.carousel]: true,
      [$style.dark]: isDarkTheme,
    }"
    @click="onClick"
  >
    <slot />
  </swiper>
</template>

<script>
import { Swiper } from 'swiper/vue';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import SwiperCore, {
  Pagination,
  Navigation,
  Autoplay,
} from 'swiper';

SwiperCore.use([
  Pagination,
  Navigation,
  Autoplay,
]);

export default {
  name: 'UiCarousel',

  components: {
    Swiper,
  },

  props: {
    slidesPerView: {
      type: [Number, String],
      default: 1,
    },
    spaceBetween: {
      type: Number,
      default: 12,
    },
    centeredSlides: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: [Object, Boolean],
      default: () => ({
        clickable: true,
      }),
    },
    navigation: {
      type: [Object, Boolean],
      default: false,
    },
    theme: {
      type: String,
      default: 'light',
      validate: (value) => ['dark', 'light'].includes(value),
    },
  },

  emits: ['click'],

  computed: {
    isDarkTheme() {
      return this.theme === 'dark';
    },
  },

  methods: {
    onClick($event) {
      this.$emit('click', $event);
    },
  },
};
</script>

<style module>
.carousel {
  position: relative;
}

.carousel :global .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: var(--color-secondary-light);
  opacity: 1;
}

.dark.carousel :global .swiper-pagination-bullet  {
  background: var(--color-primary-dark-theme);
}

.carousel:global.swiper-container-horizontal > .swiper-pagination-bullets,
.carousel :global .swiper-pagination-custom,
.carousel :global .swiper-pagination-fraction {
  bottom: 30px;
}
.carousel :global .swiper-pagination-bullet:not(:last-child) {
  margin-right: 8px;
}
.carousel :global .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--color-primary);
}
</style>
