import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

import config from '@/services/config';
import { getHTTPError } from '@/utils/errors';
import SpreadsheetService from '@/services/spreadsheet';

const useMetrika = () => {
  const route = useRoute();
  const store = useStore();
  const toast = useToast();

  const spreadsheetService = new SpreadsheetService();

  const metrikaId = config.theme === config.themes.DARK
    ? config.yandexMetrikaIdV2 : config.yandexMetrikaId;

  const metrikaName = `yaCounter${metrikaId}`;
  let metrika = window?.[metrikaName];

  const initMetrika = async () => {
    document.addEventListener(`yacounter${metrikaId}inited`, async () => {
      metrika = window?.[metrikaName];

      sendUserInfo();
      const regId = getClientId();
      setUserParameters({ UserID: regId });
      setVisitParameters({ UserID: regId });
    });
  };

  const sendUserInfo = async () => {
    document.addEventListener(`yacounter${metrikaId}inited`, async () => {
      const uid = route?.query?.uid;

      metrika = window?.[metrikaName];

      if (!metrika || !uid) return;

      try {
        const usersList = await spreadsheetService.getSpreadSheetTable();

        if (!usersList?.length) return;

        const user = usersList?.find((item) => item?.uid === uid);

        if (!user) return;

        store.commit('setMetrikaUser', user);
        metrika?.userParams(user);
      } catch (e) {
        toast.error(getHTTPError(e));
      }
    });
  };

  const reachGoal = (goal) => {
    metrika?.reachGoal(goal);
  };

  const getClientId = () => {
    const clientId = metrika?.getClientID();
    store.commit('setMetrikaClientId', clientId);

    return clientId;
  };

  const setVisitParameters = (params) => {
    metrika?.params(params);
  };

  const setUserParameters = (params) => {
    metrika?.userParams(params);
  };

  return {
    sendUserInfo,
    reachGoal,
    getClientId,
    initMetrika,
    setUserParameters,
    setVisitParameters,
  };
};

export default useMetrika;
