<template>
  <banner
    :class="classes"
    :is-small-title="true"
    :title="t('banners.rate.title')"
    :background-position="isDarkTheme ? 'right 20px' : null"
    :theme="theme"
  >
    <template #content>
      <div :class="$style.content">
        {{ t('banners.rate.text') }} <span>{{ t('banners.rate.text2') }}</span>
      </div>
    </template>
    <template #button>
      <ui-button
        color="secondary"
        :class="$style.button"
        @click="onClickRegisterPortal"
      >
        {{ t('banners.rate.button') }}
      </ui-button>
    </template>
  </banner>
</template>

<script setup>
import {
  computed,
  useCssModule,
  unref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import Banner from '@/components/banners/Banner.vue';
import UiButton from '@/kit/UiButton.vue';
import useMetrika from '@/composable/useMetrika';

import config from '@/services/config';

const style = useCssModule();

const props = defineProps({
  theme: {
    type: String,
    default: 'light',
    validate: (value) => ['dark', 'light'].includes(value),
  },
});

const { t } = useI18n();
const { reachGoal } = useMetrika();
const store = useStore();

const theme = computed(() => props.theme);
const isDarkTheme = computed(() => props.theme === 'dark');

const classes = computed(() => ({
  [style.container]: true,
  [style.dark]: unref(isDarkTheme),
}));

const metrikaClientId = computed(() => store.state.metrikaClientId);

const href = computed(() => (`${config.host}`));
const regKeycloakURI = computed(() => `auth/realms/globaltruck/protocol/openid-connect/registrations
?client_id=gt-arm
&redirect_uri=https%3A%2F%2Fportal.globaltruck.ru%2F%3F
source%3Dabout.globaltruck.ru%26regId%3D${metrikaClientId.value}
&response_mode=fragment&response_type=code&scope=openid`);

const onClickRegisterPortal = () => {
  reachGoal('enterportal');

  window.location.assign(`${href.value}${regKeycloakURI.value}`);
};
</script>

<style module>
.container {
  background-image: url('~@/assets/img/banner-rate.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 103%;
}

.content {
  margin-top: 10px;
  color: var(--color-secondary-extra-dark);
  font-size: var(--font-size-medium-header);
  line-height: var(--line-height-header);
}

.content span {
  font-weight: var(--font-weight-medium);
}

.button {
  margin-top: 20px;
  min-height: 40px;
  min-width: 200px;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bold);
}

.dark.container {
  background-image: none;
  background-color: var(--color-background-dark-theme);
}

@media screen and (max-width: 767px) {
  .container {
    position: relative;
    background-color: var(--color-banner-blue-light);
    background-image: url('~@/assets/img/banner-rate-mobile.png');
    background-repeat: no-repeat;
    background-size: auto, cover;
    background-position: 100% 50%;
  }

  .content {
    max-width: 230px;
    font-size: var(--font-size-base);
  }

  .button {
    padding: 0 8px;
    margin-top: 10px;
    min-height: unset;
    min-width: unset;
    font-size: var(--font-size-tiny);
  }
}
</style>
