<template>
  <div :class="classes">
    <ui-carousel
      :pagination="pagination"
      :navigation="navigation"
      :autoplay="autoplay"
      :speed="1200"
      :space-between="0"
      :loop="true"
      :theme="theme"
    >
      <swiper-slide>
        <banner-cabinet :theme="theme" />
      </swiper-slide>
      <swiper-slide>
        <banner-feedback :theme="theme" />
      </swiper-slide>
      <swiper-slide>
        <banner-finance :theme="theme" />
      </swiper-slide>
      <swiper-slide>
        <banner-online :theme="theme" />
      </swiper-slide>
      <swiper-slide>
        <banner-bots :theme="theme" />
      </swiper-slide>
      <swiper-slide>
        <banner-rate :theme="theme" />
      </swiper-slide>

      <div :class="$style.controls">
        <div
          v-if="isDarkTheme"
          class="navigation bannersPrev"
        >
          <svg-icon
            :class="$style.icon"
            name="arrow"
          />
        </div>

        <div :class="$style.pagination">
          <div class="banners-pagination" />
        </div>

        <div
          v-if="isDarkTheme"
          class="navigation bannersNext"
        >
          <svg-icon
            :class="$style.icon"
            name="arrow"
          />
        </div>
      </div>
    </ui-carousel>

    <div
      v-if="!isDarkTheme"
      class="navigation bannersPrev"
    >
      <svg-icon
        :class="$style.icon"
        name="arrow"
      />
    </div>
    <div
      v-if="!isDarkTheme"
      class="navigation bannersNext"
    >
      <svg-icon
        :class="$style.icon"
        name="arrow"
      />
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  reactive,
  useCssModule,
  unref,
} from 'vue';
import { SwiperSlide } from 'swiper/vue';
import config from '@/services/config';
import SvgIcon from '@/kit/SvgIcon.vue';
import UiCarousel from '@/kit/UiCarousel.vue';
import BannerCabinet from '@/components/banners/BannerCabinet.vue';
import BannerFeedback from '@/components/banners/BannerFeedback.vue';
import BannerFinance from '@/components/banners/BannerFinance.vue';
import BannerOnline from '@/components/banners/BannerOnline.vue';
import BannerBots from '@/components/banners/BannerBots.vue';
import BannerRate from '@/components/banners/BannerRate.vue';

const style = useCssModule();

const pagination = reactive({
  clickable: true,
  el: '.banners-pagination',
});

const navigation = reactive({
  prevEl: '.bannersPrev',
  nextEl: '.bannersNext',
  disabledClass: 'bannersNavDisabled',
});

const autoplay = reactive({
  delay: 5000,
});

const theme = computed(() => config.theme);
const isDarkTheme = computed(() => config.theme === config.themes.DARK);

const classes = computed(() => ({
  [style.container]: true,
  [style.dark]: unref(isDarkTheme),
}));
</script>

<style module>
.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  background: var(--color-white-100);
}
.container :global .navigation {
  position: absolute;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: var(--z-index-navigation);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-secondary-darker);
}

.dark.container :global .navigation {
  position: static;
}

@media screen and (max-width: 1268px) {
  .container :global .navigation {
    display: none;
  }
}

.container :global .bannersPrev {
  left: 75px;
}
.container :global .bannersNext {
  right: 75px;
  transform: rotate(180deg);
}
@media screen and (max-width: 1560px) {
  .container :global .bannersPrev {
    left: 16px;
  }
  .container :global .bannersNext {
    right: 16px;
  }
}
.container :global .bannersNavDisabled {
  color: var(--color-secondary-medium-light);
  cursor: not-allowed;
}
.icon {
  width: 40px;
  height: 40px;
}
.dark .icon {
  width: 32px;
  height: 32px;
}
.pagination {
  position: absolute;
  left: 0;
  bottom: 68px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.dark .pagination {
  position: static;
  margin: 0 16px;
  display: inline-flex;
  width: auto;
}

.dark .controls {
  position: absolute;
  left: 0;
  bottom: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .container :global .navigation {
    display: none;
  }

  .pagination {
    bottom: 8px;
  }

  .dark .controls {
    bottom: 8px;
  }
}
</style>
