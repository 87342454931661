<script>
import { h } from 'vue';
import VueMultiselect from 'vue-multiselect';
import SvgIcon from '@/kit/SvgIcon.vue';

export default {
  name: 'UiMultiSelect',

  components: {
    VueMultiselect,
  },

  props: {
    modelValue: {
      type: [
        String,
        Number,
        Array,
        Object,
      ],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    showNoOptions: {
      type: Boolean,
      default: false,
    },
    showNoResults: {
      type: Boolean,
      default: false,
    },
    internalSearch: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 5,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    caretIcon: {
      type: String,
      default: 'dropdown_arrow',
    },
    size: {
      type: String,
      default: null,
    },
    showClearIcon: {
      type: Boolean,
      default: true,
    },
    showExtraIcon: {
      type: Boolean,
      default: false,
    },
    showArrowIcon: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    qaKey: {
      type: String,
      default: null,
    },
  },
  methods: {
    getClearButton() {
      return h(SvgIcon, {
        class: [
          this.$style.icon,
          this.$style.removeIcon,
        ],
        style: {
          display: this.modelValue && !this.disabled && this.showClearIcon ? null : 'none',
        },
        name: 'cancel',
        emits: ['update:model-value'],
        onClick: (e) => {
          e.preventDefault();
          // eslint-disable-next-line vue/require-explicit-emits
          this.$emit('update:model-value');
        },
      });
    },
    getArrowButton() {
      return h(SvgIcon, {
        class: [
          this.$style.icon,
          this.$style.arrowIcon,
        ],
        style: {
          display: (!this.showClearIcon || this.modelValue === null) ? null : 'none',
        },
        name: this.caretIcon,
        onClick: (e) => {
          e.preventDefault();

          if (!this.$refs.multiselect) {
            return;
          }

          this.$refs.multiselect.activate();
        },
      });
    },
    getExtraButton() {
      return h(SvgIcon, {
        class: [
          this.$style.icon,
          this.$style.extraIcon,
        ],
        style: {
          display: this.isLoading || this.modelValue !== null ? 'none' : null,
        },
        name: 'open_in_new',
        emits: ['click:extra'],
        onClick: (e) => {
          // eslint-disable-next-line vue/require-explicit-emits
          this.$emit('click:extra', e);
        },
      });
    },
  },
  render() {
    const icons = this.$slots.controls || [];

    if (this.showExtraIcon) {
      icons.push(this.getExtraButton());
    }
    if (this.showClearIcon) {
      icons.push(this.getClearButton());
    }
    if (this.showArrowIcon) {
      icons.push(this.getArrowButton());
    }

    const attrs = {
      ...this.$attrs,
      modelValue: this.modelValue,
      options: this.options,
      multiple: this.multiple,
      'data-qa': this.qaKey,
      limit: this.limit,
      loading: this.isLoading,
      disabled: this.disabled,
      'allow-empty': this.allowEmpty,
      'close-on-select': this.closeOnSelect,
      'show-labels': false,
      'show-no-options': this.showNoOptions,
      'show-no-results': this.showNoResults,
      'internal-search': this.internalSearch,
      ref: 'multiselect',
    };

    attrs.class = [{
      [this.$style.multiselect]: true,
      [this.$style[this.size]]: true,
      [this.$style.isInvalid]: this.isInvalid,
      [this.$style.isLoading]: this.isLoading,
      [this.$style.isMultiple]: this.multiple,
    }];

    return h(
      VueMultiselect,
      attrs,
      {
        caret: () => [
          h(
            'div',
            {
              class: this.$style.append,
              tabindex: '-1',
            },
            [...icons],
          ),
        ],
        noOptions: () => h('div', {
          innerHTML: 'Не найдено',
        }),
        ...this.$slots,
      },
    );
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css" />

<style>
.multiselect {
  cursor: pointer;
}

.multiselect__single {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.multiselect__select {
  display: none;
}

.multiselect__single,
.multiselect__input,
.multiselect__placeholder {
  font-size: var(--font-size-medium);
  color: var(--color-secondary-darker);
  line-height: var(--line-height-large);
  margin-bottom: 0;
  padding-top: 0;
  padding-left: 0;
  min-height: initial;
  background: none;
  border-radius: 0;
}

.multiselect__placeholder {
  color: var(--color-secondary);
}

.multiselect__tags {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  align-items: center;
  padding: 20px;
  min-height: initial;
  background: none;
  border: none;
}

.multiselect__tags-wrap {
  display: flex;
  flex-wrap: wrap;
}

.multiselect__tag {
  display: flex;
  align-items: center;
  padding: 4px 4px 4px 15px;
  background: var(--color-light-blue-67);
  white-space: normal;
  margin-right: 5px;
}

.multiselect__tag > span {
  display: block;
}

.multiselect__tag-icon {
  position: static;
  line-height: unset;
  padding: 5px;
  transition: background-color .1s ease;
}

.multiselect__tag-icon:after {
  color: var(--color-gray-160);
  transition: color .1s ease;
}

.multiselect__content-wrapper {
  box-shadow: var(--multiselect-shadow);
  border: none;
  top: 100%;
  border-radius: var(--border-radius-base);
}
.multiselect--above .multiselect__content-wrapper {
  border-top: 0;
}

.multiselect--above .multiselect__content-wrapper {
  top: auto;
  border-radius: var(--border-radius-base);
}

.multiselect__option {
  white-space: normal;
}

.multiselect__option {
  padding: 12px 12px 12px 36px;
  background: var(--color-white-100);
  color: var(--color-secondary-darker);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-normal);
}

.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight {
  background-color: var(--color-secondary-lightest);
  color: var(--color-secondary-darker);
}

.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--selected {
  background-image: url('~@/assets/img/check.svg');
  background-position: 12px center;
  background-repeat: no-repeat;
}

.multiselect__spinner {
  position: absolute;
  right: 12px;
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  background: none;
  display: block;
}
</style>

<style module>
.multiselect {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  color: var(--color-black-100);
  height: var(--size-form-element-height-xlarge);
  box-sizing: border-box;
  min-height: initial;
  border-radius: var(--border-radius-base);
  background: var(--color-white-100);
}
.small.multiselect {
  background: var(--color-white-100);
  border: 1px solid var(--color-secondary-light);
  border-radius: 4px;
  height: var(--size-form-element-height-medium);
}
.small :global .multiselect__single,
.small :global .multiselect__input,
.small :global .multiselect__placeholder {
  font-size: var(--font-size-base);
  color: var(--color-secondary-darker);
  line-height: var(--line-height-large);
}
.small :global .multiselect__tags {
  padding: 6px 10px 6px 16px;
}

.small .multiselect__content-wrapper {
  box-shadow: var(--multiselect-shadow-small);
  border-radius: 0px 0px var(--border-radius-base) var(--border-radius-base);
}

.multiselect:global.multiselect--active :local .arrowIcon {
  transform: rotate(180deg);
  color: var(--color-navy-100);
}
.multiselect:hover {
  background: var(--color-secondary-lightest);
}
.multiselect:global.multiselect--active {
  background: var(--color-white-100);
}

.multiselect:global.multiselect--disabled {
  opacity: 0.33;
}

.multiselect.isInvalid {
  color: #111;
  box-shadow: inset 0 0 0 1px var(--color-red-100);
}

.multiselect.isMultiple {
  height: unset;
  min-height: var(--size-form-element-height);
}

.isLoading {
}

.icon {
  width: 22px;
  height: 22px;
}

.arrowIcon {
  color: var(--color-secondary-darker);
}

.removeIcon {
  cursor: pointer;
  color: var(--color-secondary);
}

.extraIcon {
  color: var(--color-black-67);
  cursor: pointer;
}
.extraIcon:hover {
  color: var(--color-navy-100);
}

.append {
  display: flex;
  align-items: center;
  margin-right: 8px;
  outline: none;
}
</style>
