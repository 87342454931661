import IMask from 'imask';

function fireEvent(el, eventName, data) {
  const e = document.createEvent('CustomEvent');
  e.initCustomEvent(eventName, true, true, data);
  el.dispatchEvent(e);
}

function initMask(el, opts) {
  // eslint-disable-next-line no-param-reassign
  el.maskRef = IMask(el, opts)
    .on('accept', () => fireEvent(el, 'accept', el.maskRef))
    .on('complete', () => fireEvent(el, 'complete', el.maskRef));
}

function destroyMask(el) {
  if (el.maskRef) {
    el.maskRef.destroy();
    // eslint-disable-next-line no-param-reassign
    delete el.maskRef;
  }
}

const MaskDirective = {
  beforeMount(el, directive) {
    const element = el;
    const { value: options } = directive;

    if (!options) return;

    initMask(element, options);
  },

  updated(el, directive) {
    const element = el;
    const { value: options } = directive;

    if (options) {
      if (element.maskRef) {
        element.maskRef.updateOptions(options);
        const elementValue = element;
        const { maskRef } = element;

        if (elementValue.value !== element.maskRef.value) {
          // eslint-disable-next-line no-underscore-dangle
          maskRef._onChange();
        }
      } else initMask(el, options);
    } else {
      destroyMask(el);
    }
  },

  unmounted(el) {
    destroyMask(el);
  },
};

export default MaskDirective;
