<template>
  <div
    :class="$style.container"
  >
    <div
      :class="$style.close"
      @click="$emit('close')"
    >
      <svg-icon
        :class="$style.icon"
        name="close"
      />
    </div>
    <div :class="$style.content">
      <slot />
    </div>
  </div>
</template>

<script setup>
import SvgIcon from '@/kit/SvgIcon.vue';

defineEmits(['close']);
</script>

<style module>
.container {
  position: absolute;
  left: calc(50% - 100px);
  top: -26px;
  width: 200px;
  padding: 16px;
  background: var(--color-primary-light);
  border: 1px solid var(--color-secondary-light);
  box-shadow: var(--tooltip-shadow);
  border-radius: var(--border-radius-base);
  z-index: var(--z-index-cell);
  color: var(--color-secondary-darker);
}
@media screen and (max-width: 767px) {
  .container {
    left: 32px;
    top: calc(50% - 16px);
  }
}
.content {
  font-size: var(--font-size-xsmall);
  line-height: var(--line-height-xsmall);
  font-weight: var(--font-weight-normal);
}
.close {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  user-select: none;
}
.icon {
  width: 16px;
  height: 16px;
  color: var(--color-secondary-darker);
}
</style>
