<template>
  <button
    v-if="!url"
    :class="classes"
    v-bind="$attrs"
  >
    <span :class="$style.content">
      <svg-icon
        v-if="prependIcon"
        :class="[
          $style.prependIcon,
          iconClassName,
        ]"
        :name="prependIcon"
      />
      <span>
        <svg-icon
          v-if="icon"
          :class="[
            $style.centralIcon,
            iconClassName,
          ]"
          :name="icon"
        />
        <slot v-else />
      </span>
      <svg-icon
        v-if="appendIcon"
        :class="[
          $style.appendIcon,
          iconClassName,
        ]"
        :name="appendIcon"
      />
    </span>
  </button>

  <a
    v-else
    :href="url"
    :class="classes"
    v-bind="$attrs"
  >
    <span :class="$style.content">
      <svg-icon
        v-if="prependIcon"
        :class="[
          $style.prependIcon,
          iconClassName,
        ]"
        :name="prependIcon"
      />
      <span>
        <svg-icon
          v-if="icon"
          :class="[
            $style.centralIcon,
            iconClassName,
          ]"
          :name="icon"
        />
        <slot v-else />
      </span>
      <svg-icon
        v-if="appendIcon"
        :class="[
          $style.appendIcon,
          iconClassName,
        ]"
        :name="appendIcon"
      />
    </span>
  </a>
</template>

<script>
import SvgIcon from '@/kit/SvgIcon.vue';

export default {
  name: 'UiButton',

  components: {
    SvgIcon,
  },

  props: {
    appearance: {
      type: String,
      default: 'filled',
      validate: (value) => ['filled', 'clean'].includes(value),
    },
    color: {
      type: String,
      default: 'primary',
      validate: (value) => ['primary', 'secondary', 'alternate'].includes(value),
    },
    size: {
      type: String,
      default: 'middle',
      validate: (value) => ['small', 'middle', 'large'].includes(value),
    },
    url: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    iconClassName: {
      type: String,
      default: null,
    },
  },

  computed: {
    classes() {
      const {
        $style,
        appearance,
        size,
        color,
        icon,
        prependIcon,
        appendIcon,
      } = this;

      return {
        [$style.base]: true,
        [$style[appearance]]: true,
        [$style[color]]: true,
        [$style[size]]: true,
        [$style.isHaveIcon]: icon || false,
        [$style.isHavePrependIcon]: prependIcon || false,
        [$style.isHaveAppendIcon]: appendIcon || false,
      };
    },
  },
};
</script>

<style module>
.base {
  position: relative;
  font: inherit;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-small);
  text-decoration: none;

  border: 1px solid;
  border-radius: var(--border-radius-medium);
  outline: none;

  display: flex;
  place-items: center;
  place-content: center;

  padding: 0 16px;
  height: var(--size-form-element-height);

  transition: border-color, background-color var(--timing-blink-animation) ease-in-out;

  color: var(--color-secondary-darker);
}
.base:not(:disabled) {
  cursor: pointer;
}
.base.medium {
  height: var(--size-form-element-height-medium);
}

.base.large {
  height: var(--size-form-element-height-large);
}

.base.isHavePrependIcon,
.base.isHaveAppendIcon {
  padding: 0 16px;
}

.base.isHaveIcon {
  padding: 0 4px;
}

.primary {
  color: var(--color-white-100);
}
.primary:not(:disabled),
.primary:not(:disabled):visited {
  background-color: var(--color-primary);
  border-color:  var(--color-primary);
}
.primary:not(:disabled):hover {
  background-color: var(--color-primary-darker);
  border-color:  var(--color-primary-darker);
}

.primary:not(:disabled):active {
  background-color: var(--color-primary-dark);
}
.primary:disabled {
  background-color: var(--color-primary-light);
  border-color:  var(--color-primary-light);
}

.secondary {
  color: var(--color-white-100);
}
.secondary:not(:disabled),
.secondary:not(:disabled):visited {
  background-color: var(--color-secondary-darker);
  border-color:  var(--color-secondary-darker);
}
.secondary:not(:disabled):hover {
  background-color: var(--color-primary);
  border-color:  var(--color-primary);
}

.secondary:not(:disabled):active {
  background-color: var(--color-primary-dark);
  border-color: var(--color-primary-dark);
}

.secondary:disabled {
  background-color: var(--color-primary-dark);
  border-color: var(--color-primary-dark);
}

.fillter.alternate {
  background-color: transparent;
}
.alternate:not(:disabled),
.alternate:not(:disabled):visited {
  border-color: var(--color-secondary-light);
}
.alternate:not(:disabled):hover {
  border-color: var(--color-secondary-light);
  background-color: var(--color-secondary-lightest);
}

.alternate:not(:disabled):active {
  border-color: var(--color-secondary-extra-light);
  background-color: var(--color-secondary-extra-light);
}
.alternate:disabled {
  color: var(--color-secondary-medium-light);
  border-color: var(--color-secondary-medium-light);
}

.danger:not(:disabled) {
  background: var(--color-danger);
}
.danger:not(:disabled):hover {
  background-color: var(--color-red-120);
}

.danger:not(:disabled):active {
  background-color: var(--color-red-150);
}

.primary.clean {
  background: transparent;
  border: none;
  color: var(--color-primary);
}

.primary.clean:hover,
.primary.clean:active {
  background: transparent;
  border: none;
  color: var(--color-primary-darker);
}

.icon {
  background: transparent;

  border-radius: 0;
  border: none;
}
.icon:disabled {
  color: var(--color-black-13);
}

.icon.primary:not(:disabled) {
  color: var(--color-black-67);
}
.icon.primary:not(:disabled):hover,
.icon.primary:not(:disabled):active {
  border-color: var(--color-light-blue-100);
}

.icon.primary:not(:disabled):active {
  color: var(--color-light-blue-100);
}

.outline {
  background-color: transparent;
}
.outline.primary:not(:disabled) {
  border-color: var(--color-light-blue-100);
  color: var(--color-light-blue-100);
}
.outline.primary:not(:disabled):hover {
  border-color: var(--color-navy-120);
  color: var(--color-navy-120);
}
.outline.primary:not(:disabled):active {
  border-color: var(--color-navy-150);
  color: var(--color-navy-150);
}

.outline.danger:not(:disabled) {
  border-color: var(--color-red-100);
  color: var(--color-red-100);
}
.outline.danger:not(:disabled):hover {
  border-color: var(--color-red-120);
  color: var(--color-red-120);
}

.outline.danger:not(:disabled):active {
  border-color: var(--color-red-150);
  color: var(--color-red-150);
}

.centralIcon {
  width: 24px;
  height: 24px;
  color: var(--color-black-67);
}

.prependIcon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.appendIcon {
  margin-left: 8px;
  width: 20px;
  height: 20px;
}

.content {
  display: flex;
  align-items: center;
}
</style>
